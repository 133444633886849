.enumeration-entry.enumeration-entry {
    // Vars

    // Support

    // Module
    & {
        & {
            display: flex;
        }

        .enumeration-entry--title {
            @include type--h3;
            margin-bottom: 8px;
            padding-left: 24px;
        }

        .enumeration-entry--description {
            @include type--paragraph;
            padding-left: 24px;
        }

        .enumeration-entry--row {
            justify-content: center;
        }

        .enumeration-entry--item-wrapper-ordered {
            counter-reset: item;
            list-style-type: none;
        }

        .enumeration-entry--item-ordered {
            position: relative;
            padding-left: 24px;

            &.enumeration-entry--item-ordered__without-title {
                margin-bottom: 20px;

                @include mq('<=md') {
                    margin-bottom: 12px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &.enumeration-entry--item-ordered__with-title {
                margin-bottom: 40px;

                @include mq('<=md') {
                    margin-bottom: 32px;
                }

                @include mq('<=sm') {
                    margin-bottom: 24px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &::before {
                @include type--h2;
                color: $c--accent-blue;
                content: counter(item) ' ';
                counter-increment: item;
                position: absolute;
                top: 0;
                left: 0;
                line-height: 0.7;
                white-space: nowrap;

                @include mq('<=lg') {
                    line-height: 0.9;
                }
            }
        }

        .enumeration-entry--item-unordered.enumeration-entry--item-unordered {
            position: relative;
            padding-left: 24px;

            &.enumeration-entry--item-unordered__without-title {
                margin-bottom: 16px;
                margin-top: 0;

                @include mq('<=md') {
                    margin-bottom: 12px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &.enumeration-entry--item-unordered__with-title {
                margin-bottom: 40px;

                @include mq('<=md') {
                    margin-bottom: 32px;
                }

                @include mq('<=sm') {
                    margin-bottom: 24px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &::before {
                @include type--h2;
                color: $c--accent-blue;
                content: '■';
                position: absolute;
                top: 0;
                left: 0;
                line-height: 0.7;

                @include mq('<=lg') {
                    line-height: 0.9;
                }
            }
        }
    }

    // Facets

    // States
}
