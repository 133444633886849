////
/// Form field facets and state generation library
////

/// Dependencies and components
///
@import 'ui-form-field__helpers';
@import 'ui-form-field__states';
@import 'ui-form-field__facets';

/// Form field facets
///
.ui-form-field {
    // Vars

    // Support

    // Module

    // Facets
    &.ui-form-field__base {
        @include ui-form-field--primary;
    }

    // States
}
