.form-field-messages {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        flex: 0 1 100%;
        margin-top: 8px;

        @include mq('>md') {
            padding-left: 188px;
        }

        .form-field-messages--message {
            @include type--form-text;

            display: block;
            font-size: 1.2rem;
            line-height: 1.3;

            ~ .form-field-messages--message {
                margin-top: 3px;
            }

            &.form-field-messages--message__info {
                color: $c--monochrome-steel;
            }

            &.form-field-messages--message__error {
                color: $c--signal-error;
            }
        }
    }

    // Facets
    & {
        &.form-field-messages__bool {
            @include mq('>md') {
                padding-left: 0;
            }
        }
    }

    // States
}
