.list-icons-entry {
    // Vars

    // Support

    // Module
    & {
        display: block;
        text-align: center;
        overflow: hidden;

        .list-icons-entry--title {
            @include type--label;
            margin-bottom: 8px;

            @include mq('<=lg') {
                margin-bottom: 4px;
            }
        }

        .list-icons-entry--text {
            @include type--paragraph-small;
        }

        .list-icons-entry--link {
            @include has-focus(true) {
                color: $c--accent-blue;

                .list-icons-entry--icon {
                    // color the icon using the css filters
                    // that's a bit a hack but it's backward compatible and flexible enough to fit the requirements
                    // color generated via https://angel-rs.github.io/css-color-filter-generator/
                    filter: brightness(0) saturate(100%) invert(42%) sepia(77%) saturate(2886%) hue-rotate(173deg) brightness(95%) contrast(101%);
                }
            }
        }
    }

    // Facets

    // States
}
