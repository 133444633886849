.list-logo {
    // Vars
    $logo-width-big: 120px;
    $logo-height-big: 80px;
    $logo-width-small: 90px;
    $logo-height-small: 60px;

    // Support

    // Module
    & {
        & {
            @include grid--root((row: 'wrapper', cols: ('entry')));
            @include grid--space((gutter: (12px 16px), row: 'wrapper', cols: ('entry')));
        }

        .list-logo--title {
            margin-bottom: 16px;
            color: $c--brand-blue-dark;
            text-align: center;
        }

        .list-logo--text {
            @include type--paragraph;
            margin-bottom: 16px;
            justify-content: center;
            text-align: center;
        }

        .list-logo--text-col {
            @include grid--span((ratio: 1 / 1));
            @include grid--span((ratio: 1 / 2, mq: '>lg'));
        }

        .list-logo--more-link {
            margin-top: 24px;
        }

        .list-logo--col {
            margin: 0 auto;

            &.list-logo--col__base {
                @include grid--span((ratio: 1 / 1, mq: '<=md'));
                @include grid--span((ratio: 3 / 4, mq: '>md'));
            }

            &.list-logo--col__link {
                @include grid--span((ratio: 4 / 12, mq: '>md'));
                @include grid--span((ratio: 8 / 12, mq: 'md'));
                @include grid--span((ratio: 1 / 1, mq: '<=sm'));
            }
        }

        .list-logo--wrapper {
            display: flex;
            justify-content: center;
        }

        .list-logo--entry {
            display: flex;
            align-items: center;
            justify-content: center;

            @include grid--col((ratio: 3 / 12, mq: '>sm'));
            @include grid--col((ratio: 6 / 12, mq: '<=sm'));
        }

        .list-logo--image {
            display: inline-block;
            max-width: $logo-width-big;
            max-height: $logo-height-big;

            @include mq('<=md') {
                max-width: $logo-width-small;
                max-height: $logo-height-small;
            }
        }
    }

    // Facets

    // States
}
