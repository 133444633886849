.error-page {
    // Vars

    // Support

    // Module
    & {
        & {
            @include mq('>lg') {
                padding-bottom: 80px;
            }

            @include mq('<=lg') {
                padding-bottom: 60px;
            }
        }

        .error-page--col {
            @include grid--span((ratio: 8 / 12, mq: '>md'));
        }
    }

    // Facets

    // States
}
