@mixin richtext-lists {
    ul {
        list-style-type: none;
        padding-left: 0;

        li {
            position: relative;
            padding-left: 37px;
            list-style: none;

            @include mq('>lg') {
                padding-left: 51px;
            }
        }

        li::before {
            content: '';
            left: 12px;
            top: 7px;
            display: inline-block;
            vertical-align: middle;
            background: $c--accent-blue;
            width: 9px;
            height: 9px;

            @include mq('>lg') {
                left: 16px;
                top: 8px;
                width: 11px;
                height: 11px;
            }
        }
    }

    ol {
        list-style-type: none;
        padding-left: 0;

        li {
            position: relative;
            padding-left: 37px;
            list-style: none;

            @include mq('>lg') {
                padding-left: 51px;
            }
        }

        li::before {
            @include type--ordered-list-counter;

            content: counter(li);
            left: 8px;
            min-width: 24px;
            color: $c--accent-blue;

            @include mq('>lg') {
                left: 12px;
            }
        }
    }
}
