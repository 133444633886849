.collapsible-panel-steps {
    // Vars

    // Support

    // Module
    & {
        .collapsible-panel-steps--header {
            margin-bottom: 32px;
        }

        .collapsible-panel-steps--row {
            justify-content: center;
        }

        .collapsible-panel-steps--col {
            @include grid--span((ratio: 1 / 2, mq: '>lg'));
            @include grid--span((ratio: 2 / 3, mq: ('>md' '<=lg')));
            @include grid--span((ratio: 3 / 3, mq: '<=md'));
        }

        .collapsible-panel-steps--actions {
            margin-top: 16px;
        }

        .collapsible-panel-steps--expander {
            cursor: pointer;
        }

        .collapsible-panel-steps--accordion {
            position: relative;
            overflow: hidden;

            &::after {
                @include overlay;
                content: '';
                pointer-events: none;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $c--monochrome-white 180%);
                opacity: 1;
                transition: $trs--base opacity;
            }

            &.collapsible-panel-steps--accordion__is-open {
                &::after {
                    opacity: 0;
                }
            }

            & h3 {
                margin-top: 0;
            }
        }

        .collapsible-panel-steps--content.collapsible-panel-steps--content {
            min-height: 260px;

            > ul {
                > li {
                    width: auto;
                }
            }

            @include mq('<=md') {
                min-height: 132px;
            }
        }
    }

    // Facets

    // States
}
