.facts-image {
    // Vars
    // Support
    // Module
    & {
        .facts-image--col {
            @include grid--span((ratio: 5 / 12));
            @include grid--span((ratio: 6 / 12, mq: 'lg'));
            @include grid--span((ratio: 12 / 12, mq: '<=md'));
            padding-bottom: $sz--inner-spacing-desktop;

            &:nth-child(even) {
                padding-top: $sz--inner-spacing-desktop;
            }

            @include mq('<=md') {
                padding-bottom: $sz--inner-spacing-mobile;

                &:nth-child(even) {
                    padding-top: 0;
                }
            }

            &:last-child {
                padding-bottom: 0;
            }

            &:nth-child(odd) {
                @include grid--push((ratio: 1 / 12));
                @include grid--push((ratio: 0, mq: '<=lg'));
            }
        }
    }

    // Facets
    // States
}
