/// Field styles
///
@mixin ui-form-field--primary-file() {
    // Vars

    // Support

    // Module
    & {
        line-height: normal;

        > label {
            width: 100%;
            cursor: pointer;

            @include mq('>md') {
                display: flex;
            }
        }

        .ui-form-field--input-container {
            position: relative;
            padding-top: 16px;
        }

        .ui-form-field--title-label {
            margin-bottom: 0;
            cursor: pointer;
            transition: $trs--common-props;
        }

        .ui-form-field--input {
            @include hide-visually;
        }

        .ui-form-field--input-text {
            @include type--form-text;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        .ui-form-field--input-text-content {
            margin-bottom: 2px;
        }

        .ui-form-field--input-cancel-icon {
            margin-left: 8px;
        }
    }

    // States
    & {
        // Base
        @include ui-form-field--apply-states(()) {
            @include ui-form-field--primary-file-base;
        }

        // Empty
        @include ui-form-field--apply-states(('filled': false)) {
            @include ui-form-field--primary-file-empty;
        }
    }
}

/// Base
///
@mixin ui-form-field--primary-file-base {
    .ui-form-field--input-text {
        color: $c--label-text;
    }

    .ui-form-field--input-cancel-icon {
        @include is-visible(true, $trs--base-speed);
    }
}

/// Empty
///
@mixin ui-form-field--primary-file-empty {
    .ui-form-field--input-text {
        color: $c--label-link;
    }

    .ui-form-field--input-cancel-icon {
        @include is-visible(false, $trs--base-speed);
    }
}
