@mixin section-margin() {
    @include mq('>md') {
        margin-bottom: 80px;
    }

    @include mq('<=md') {
        margin-bottom: 60px;
    }

    .section-margin {
        margin-bottom: 0;
    }
}

@mixin section-margin-small() {
    @include mq('>md') {
        margin-bottom: 60px;
    }

    @include mq('<=md') {
        margin-bottom: 40px;
    }
}
