/* stylelint-disable */


.chatbot {
    z-index: z-index('chatbot');
    position: fixed;
    right: 20px;
    bottom: 20px;

    .description-value {
        text-align: center;
    }

    .btn-helparea {
        @include type--paragraph-small;
        position: absolute;
        bottom: 0;
        right: 0px;
        width: 130px;
        height: 125px;
        background: $c--monochrome-white url('../../img/ipt-HR-Robo.png') no-repeat bottom;
        border-radius: 77px;
        color: #c7004b;
        font-weight: bold;
        padding: 0px;
        cursor: pointer;
    }

    .btn-helparea a {
        margin: 0;
        color: #c7004b;
    }

    .button-Area {
        position: inherit;
        bottom: 35px;
        right: 160px;
        width: 175px;
        height: 60px;
        border: none;
        text-align: left;
        font-style: normal;
        color: #c7004b;
        background: white;
        padding: 14px 10px 14px 15px;
        box-shadow: -0px -0px 12px grey, 0px 0px 12px #ccc;
        cursor: pointer;
    }

    .dreieck {
        position: relative;
        top: 50px;
        right: 37px;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid #c7004b;
        transform: rotate(90deg);
    }
}

/* stylelint-enable */
