.background-pattern {
    // Vars
    $pattern-ids: (
        'pattern-center-curl',
        'pattern-left-concave',
        'pattern-left-curl',
        'pattern-left-wave',
        'pattern-right-circle',
        'pattern-right-concave',
        'pattern-right-curl',
        'pattern-right-swirl'
    );

    // Support

    // Module
    & {
        .background-pattern--path-full {
            stroke: rgba($c--accent-pink, 0.4);
            stroke-dasharray: 10000;
            visibility: hidden;
            opacity: 0;
        }

        .background-pattern--path-dashed {
            stroke-dasharray: 20;
        }
    }

    // Facets
    & {
        // Patterns
        @each $pattern in $pattern-ids {
            &.background-pattern__pattern-#{$pattern} {
                .background-pattern--group[id='#{$pattern}'] {
                    .background-pattern--path-full {
                        visibility: visible;
                    }
                }
            }
        }

        // Accent colors (in case we need it someday)
        //@each $color-key, $color-value in $accents {
        //    &.background-pattern__color-accent-#{$color-key} {
        //        .background-pattern--path-full {
        //            stroke: rgba($color-value, 0.4);
        //        }
        //    }
        //}

        // Silver (in case we need it someday)
        //&.background-pattern__color-monochrome-silver {
        //    .background-pattern--path-full {
        //        stroke: rgba($c--monochrome-silver, 0.4);
        //    }
        //}
    }

    // States
}
