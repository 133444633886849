.list-entry {
    // Vars

    // Support

    // Module
    & {
        & {
            opacity: 0;
            transition-duration: 400ms;
            transform: translateY(150px);
            transition-property: transform, opacity;
        }

        .list-entry--link {
            display: block;
        }

        .list-entry--image {
            display: block;
            width: 100%;
            transition: transform $trs--base;
        }

        .list-entry--image-wrapper {
            display: block;
            width: 100%;
            overflow: hidden;
        }

        .list-entry--hover-image {
            display: block;
            width: 100%;
        }

        .list-entry--visual-placeholder {
            padding-top: 100% / 4 * 3;

            @include mq('print') {
                display: none;
            }
        }

        .list-entry--visual {
            @include mq('screen') {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .list-entry--image-tags {
            position: absolute;
            top: 0;
            right: 16px;

            @include mq('<=md') {
                right: 12px;
            }
        }

        .list-entry--content {
            display: flex;
            flex-direction: column;
            margin-top: 12px;

            @include mq('>lg') {
                margin-top: 16px;
            }
        }

        .list-entry--label {
            @include type--paragraph-small;
        }

        .list-entry--title {
            @include type--h3;
            margin-top: 4px;
            margin-bottom: 4px;
            transition: color $trs--base;

            @include mq('>lg') {
                margin-top: 8px;
                margin-bottom: 8px;
            }
        }

        .list-entry--description {
            @include type--paragraph;
        }

        .list-entry--tags {
            margin-top: 12px;

            @include mq('>lg') {
                margin-top: 16px;
            }
        }
    }

    // Facets
    & {
        &.list-entry__blog {
            .list-entry--visual-placeholder {
                padding-top: 100% / 16 * 9;
            }
        }

        &.list-entry__story {
            .list-entry--label {
                color: $c--accent-pink;
            }

            .list-entry--visual-placeholder {
                padding-top: 100% / 16 * 9;
            }
        }

        &.list-entry__team {
            text-align: center;

            .list-entry--title {
                @include type--label-small;
                margin-bottom: 2px;
                margin-top: 0;
            }

            .list-entry--description {
                @include type--paragraph-small-reduced-lh;
            }
        }
    }

    // States
    & {
        &.list-entry__active {
            opacity: 1;
            transform: translateY(0);

            .list-entry--visual {
                height: auto;
            }
        }

        & {
            .list-entry--link {
                @include has-focus(true) {
                    .list-entry--title {
                        color: $c--accent-blue;
                    }

                    .list-entry--image {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}
