.list-loader {
    // Vars

    // Support

    // Module
    & {
        & {
            overflow-anchor: none;
        }
    }

    // Facets

    // States
}
