.iframe {
    // Vars

    // Support

    // Module
    & {
        .iframe--title {
            margin-bottom: 32px;
            color: $c--brand-blue-dark;
        }

        .iframe--row {
            justify-content: center;
        }

        .iframe--col {
            @include grid--span((ratio: 12 / 12, mq: '<=md'));
            @include grid--span((ratio: 8 / 12, mq: 'lg'));
            @include grid--span((ratio: 6 / 12, mq: '>lg'));
        }

        .iframe--media {
            margin-top: 48px;
        }
    }

    // Facets

    // States
}
