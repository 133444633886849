.navigation-controller {
    // Vars
    $sz--navigation-height-desktop-xl: $sz--navigation-meta-height-desktop + $sz--navigation-main-height-desktop-xl;
    $sz--navigation-height-desktop-lg: $sz--navigation-meta-height-desktop + $sz--navigation-main-height-desktop-lg;

    // Support

    // Module
    & {
        width: 100%;

        @include mq('screen') {
            z-index: z-index('navigation-controller');
            position: fixed;
            top: 0;
            left: 0;
            opacity: 0;
            transition: opacity $trs--base;
        }

        .navigation-controller--revealer {
            @include mq('screen') {
                transition: transform $trs--base;
                position: absolute;
                left: 0;
                width: 100%;
                height: $sz--navigation-height-desktop-xl;
                transform: translateY(0);
            }

            @include mq('lg') {
                height: $sz--navigation-height-desktop-lg;
            }
        }

        .navigation-controller--sub-nav {
            position: relative;
            transition: top $trs--scroll-reveal;
            border-bottom: 1px solid $c--monochrome-medium-gray;
        }

        .navigation-controller--back-button {
            z-index: z-index('back-button');
            position: relative;
            top: 34px;
            left: 38px;

            @include mq('<=sm') {
                top: 24px;
                left: 24px;
            }
        }
    }

    // Facets
    & {
        .navigation-controller--revealer {
            @include mq('>lg') {
                &.navigation-controller--revealer__unpinned {
                    transform: translateY($sz--navigation-height-desktop-xl * -1);
                }
            }

            @include mq('lg') {
                &.navigation-controller--revealer__unpinned {
                    transform: translateY($sz--navigation-height-desktop-lg * -1);
                }
            }
        }

        &.navigation-controller__has-back-button {
            .navigation-controller--sub-nav {
                border-bottom: none;
            }
        }
    }

    &.navigation-controller__mounted {
        opacity: 1;
    }

    // States
}
