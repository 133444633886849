.u-rich-text {
    // Vars

    // Support

    // Module
    & {
        :first-child {
            margin-top: 0;
        }

        h2 {
            margin-top: 80px;
        }

        h3, h4 {
            margin-top: 40px;
        }

        p {
            margin-top: 12px;
        }

        h1, h2, h3, h4, h5, h6 {
            ~ p {
                margin-top: 16px;
            }
        }

        ul, ol {
            margin-top: 20px;
        }

        &.u-rich-text {
            &.u-rich-text {
                @include richtext-lists;
            }
        }
    }

    // Facets

    // States
}
