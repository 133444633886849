.ui-accordion {
    // Vars
    @if not variable-exists(trs--accordion) {
        $trs--accordion-speed: 300ms !global;
        $trs--accordion-fn: ease !global;
        $trs--accordion: $trs--accordion-speed $trs--accordion-fn !global;
    }

    // Support

    // Module
    & {
        .ui-accordion--head {
            display: block;
            width: 100%;
            text-align: left;
            cursor: pointer;
        }

        .ui-accordion--body {
            overflow: hidden;
            max-height: 0;
            opacity: 0;
            transform: rotateZ(1turn);
        }
    }

    // Facets
    & {
        // Base
        &.ui-accordion__base,
        &.ui-accordion__collapsible-panel {
            &.ui-accordion__is-animating {
                & {
                    .ui-accordion--body {
                        @include is-visible(false, $trs--accordion-speed, opacity, max-height);
                    }
                }

                &.ui-accordion__is-open {
                    .ui-accordion--body {
                        @include is-visible(true, $trs--accordion-speed, opacity, max-height);
                    }
                }
            }
        }

        // In `collapsible-panel`
        &.ui-accordion__collapsible-panel {
            // Open
            & {
                .ui-accordion--body {
                    max-height: 132px;
                    visibility: inherit;
                    opacity: 1;
                }
            }
        }

        &.ui-accordion__steps {
            // Open
            & {
                .ui-accordion--body {
                    max-height: 265px;
                }
            }
        }
    }

    // States
    & {
        // Closed
        & {
            .ui-accordion--body {
                visibility: hidden;
                opacity: 0;
            }
        }

        // Open
        &.ui-accordion__is-open {
            .ui-accordion--body {
                visibility: inherit;
                opacity: 1;
            }
        }
    }
}
