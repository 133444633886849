.list-expert-random {
    // Vars

    // Support

    // Module
    & {
        @include mq('>md') {
            margin: -80px 0;
        }

        .list-expert-random--text {
            display: block;
            margin-top: 16px;
        }

        .list-expert-random--more-link {
            margin-top: 24px;

            @include mq('<=md') {
                display: none;
            }
        }

        .list-expert-random--more-link-mobile {
            order: 3;
            width: 100%;

            @include mq('>md') {
                display: none;
            }
        }

        .list-expert-random--content-wrapper {
            pointer-events: none;
        }

        .list-expert-random--content {
            pointer-events: auto;
        }
    }

    // Facets
    & {
        &.list-expert-random__border-top {
            @include mq('>md') {
                margin-top: 0;
                border-top: 1px solid $c--monochrome-medium-gray;
            }
        }

        &.list-expert-random__border-bottom {
            @include mq('>md') {
                margin-bottom: 0;
                border-bottom: 1px solid $c--monochrome-medium-gray;
            }
        }
    }

    // States
}
