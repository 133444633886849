.navigation-sub-mobile {
    // Vars

    // Support

    // Module
    & {
        z-index: z-index('navigation-sub-mobile');
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        margin-top: $sz--navigation-meta-height-mobile;
        background-color: $c--monochrome-silver;

        @include mq('>md') {
            display: none;
        }

        .navigation-sub-mobile--container.navigation-sub-mobile--container {
            @include mq('<=sm') {
                max-width: none;
                padding: 0;
            }
        }

        .navigation-sub-mobile--observer {
            position: relative;

            @include mq('<=sm') {
                padding: 0 16px;
            }
        }

        .navigation-sub-mobile--nav {
            @include grid--span((ratio: 1 / 1));
        }
    }

    // Facets

    // States
}
