.expertises-overlay {
    // Vars
    $sz--overlay-width-base: 400px;
    $sz--overlay-header-height: 60px;
    $sz--overlay-footer-height: 16px;
    $sz--overlay-border-radius: 8px;

    // Support

    // Module
    & {
        overflow: hidden;
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        width: $sz--overlay-width-base;

        @include mq('<=sm') {
            height: 100%;
            width: 100%;
            background-color: $c--monochrome-white;
        }

        .expertises-overlay--header {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            height: $sz--overlay-header-height;
            background-color: $c--monochrome-white;
            border-bottom: 1px solid $c--monochrome-medium-gray;

            @include mq('>sm') {
                border-top-left-radius: $sz--overlay-border-radius;
                border-top-right-radius: $sz--overlay-border-radius;
            }
        }

        .expertises-overlay--body {
            @include ios-native-scrolling;
            width: 100%;
            background-color: $c--monochrome-white;
            padding: 16px 48px 16px 40px;
            margin-top: $sz--overlay-header-height;
            margin-bottom: $sz--overlay-footer-height;

            @include mq('<=sm') {
                height: 100%;
                margin-bottom: 0;
            }
        }

        .expertises-overlay--footer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: $sz--overlay-footer-height;
            border-top: 1px solid $c--monochrome-medium-gray;
            background-color: $c--monochrome-white;
            border-bottom-left-radius: $sz--overlay-border-radius;
            border-bottom-right-radius: $sz--overlay-border-radius;

            @include mq('<=sm') {
                display: none;
            }
        }
    }

    // Facets

    // States
}
