.list-icons {
    // Vars

    // Support

    // Module
    & {
        @include section-background($selector: 'list-icons', $add-section-padding: true);

        .list-icons--header {
            margin-bottom: 16px;
            color: $c--brand-blue-dark;
            text-align: center;
        }

        .list-icons--description {
            margin-top: 16px;
        }

        .list-icons--row.list-icons--row.list-icons--row {
            flex-flow: row nowrap;

            @include mq('>lg') {
                justify-content: center;
            }
        }

        .list-icons--actions {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            margin-top: 24px;
        }
    }

    // Facets
    & {
        &.list-icons__three-items {
            .list-icons--entry {
                @include grid--span((ratio: 1 / 4, mq: '>lg'));
                @include grid--span((ratio: 1 / 3, mq: 'lg'));
                @include grid--span((ratio: 2 / 5, mq: 'md'));
                @include grid--span((ratio: 3 / 4, mq: '<=sm'));
                overflow: hidden;
            }
        }

        &.list-icons__four-items {
            .list-icons--entry {
                @include grid--span((ratio: 1 / 4, mq: '>md'));
                @include grid--span((ratio: 2 / 5, mq: 'md'));
                @include grid--span((ratio: 3 / 4, mq: '<=sm'));
                overflow: hidden;
            }
        }
    }

    // States
}
