.steps {
    // Vars
    $swiper-panel--slider-stories-config: (
        (mq: 'xxl', width: 580px, gutter: 220px),
        (mq: 'xl', width: 580px, gutter: 140px),
        (mq: 'lg', width: 444px, gutter: 68px),
        (mq: 'md', width: 264px, gutter: 120px),
        (mq: '<=sm', width: 264px, gutter: 24px),
    );

    // Support

    // Module
    & {
        .steps--title {
            @include type--h2;
            margin-bottom: 16px;
            text-align: center;
        }

        .steps--lead {
            @include type--paragraph;
            margin-bottom: 32px;
            text-align: center;
        }

        .steps--col {
            @include grid--span((ratio: 6 / 12, mq: '>lg'));
            @include grid--span((ratio: 8 / 12, mq: 'lg'));
            @include grid--span((ratio: 10 / 12, mq: 'md'));
            @include grid--span((ratio: 4 / 4, mq: '<=sm'));
        }

        .steps--col-swiper {
            @include grid--span((ratio: 12 / 12));
        }

        .steps--row {
            justify-content: center;
        }

        .steps--line {
            position: relative;
            top: 64px;
            border-bottom: 6px solid $c--brand-blue-dark;
        }

        .steps--slide-container {
            &::before {
                content: '';
                position: relative;
                top: 64px;
                display: block;
                background-color: $c--brand-blue-dark;
                height: 6px;
            }

            @each $value in $swiper-panel--slider-stories-config {
                $mq: map-get($value, 'mq');
                $width: map-get($value, 'width');
                $gutter: map-get($value, 'gutter');

                &:first-child, &:last-child {
                    @include mq($mq) {
                        &::before {
                            width: $width + $gutter / 2;
                        }
                    }
                }

                &:first-child {
                    @include mq($mq) {
                        &::before {
                            left: $gutter / 2;
                        }
                    }
                }
            }
        }

        .steps--slide {
            display: flex;
            height: 100%;
            min-height: 428px;
        }

        .steps--actions {
            display: flex;
            justify-content: center;
            margin-top: 32px;


            @include mq('<=lg') {
                margin-top: 24px;
            }

            @include mq('<=sm') {
                margin: 24px 16px 0;
            }
        }
    }

    // Facets
    & {
        @include section-background($selector: 'steps', $add-section-padding: true);
    }

    // States
}
