/// Primary
///
@mixin btn--primary {
    // Vars

    // Support

    // Module

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--primary-base;
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--primary-focus;
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': default, 'active': true)) {
            @include btn--primary-active;
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default)) {
            @include btn--primary-disabled;
        }
    }
}

/// Base
///
@mixin btn--primary-base {
    color: $c--monochrome-white;
    background-color: $c--accent-blue;
    border-color: transparent;
}

/// Focus
///
@mixin btn--primary-focus {
    background-color: $c--brand-blue;
}

/// Active
///
@mixin btn--primary-active {
    background-color: $c--brand-blue;
}

/// Disabled
///
@mixin btn--primary-disabled {
    color: $c--monochrome-silver;
    background-color: $c--monochrome-light-gray;
}
