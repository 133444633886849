.footer-meta {
    // Vars

    // Support

    // Module
    & {
        @include type--paragraph-x-small;
        @include grid--span((ratio: 1 / 2));
        text-align: right;

        @include mq('<=sm') {
            @include grid--span((ratio: 1 / 1));
            text-align: left;
            margin-top: 15px;
        }

        .footer-meta--link {
            @include type--paragraph-x-small;
        }
    }

    // Facets

    // States
}
