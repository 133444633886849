// stylelint-disable plugin/selector-bem-pattern

////
/// Overwrite styles for the `swiper` module.
/// @link https://idangero.us/swiper/
////

.swiper-pagination {
    top: 100%;
    display: flex;
    justify-content: center;
    padding-top: 32px;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: auto;
}

.swiper-pagination-bullet {
    background: rgba($c--brand-blue-dark, 0.2);
    transition: background-color $trs--base;
    opacity: 1;
}

.swiper-pagination-bullet-active {
    background: $c--brand-blue-dark;
}

.swiper-button-prev, .swiper-button-next {
    z-index: 1;
    top: 50%;
    display: block;
    width: auto;
    height: auto;
    margin-top: 0;
    padding: 20px;
    border-radius: 12px;
    background: $c--monochrome-white;
    transition: transition-props($trs--base, color, opacity);

    @include has-focus(true) {
        color: $c--accent-blue;
    }
}

.swiper-button-next.swiper-button-disabled {
    pointer-events: auto;
}

.swiper-slide {
    height: auto;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    //
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    //
}

