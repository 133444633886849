.swiper-panel {
    // Vars

    // Support

    // Module
    & {
        overflow: hidden;

        .swiper-panel--nav-btn {
            box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);

            &.swiper-panel--nav-btn__next {
                right: auto;
                left: 100%;
            }

            &.swiper-panel--nav-btn__prev {
                left: auto;
                right: 100%;
            }
        }

        .swiper-panel--nav {
            @include mq('<=md') {
                display: none;
            }
        }

        .swiper-panel--outer-wrapper {
            margin-bottom: 40px;
            overflow: visible;
        }

        .swiper-panel--inner-wrapper {
            display: flex;
        }
    }

    // Facets
    & {
        &.swiper-panel__no-buttons {
            .swiper-panel--nav {
                display: none;
            }
        }

        &.swiper-panel__base {
            $swiper-panel--slider-stories-config: (
                (mq: 'xxl', width: 580px, gutter: 220px),
                (mq: 'xl', width: 580px, gutter: 140px),
                (mq: 'lg', width: 444px, gutter: 68px),
                (mq: 'md', width: 264px, gutter: 120px),
                (mq: '<=sm', width: 264px, gutter: 24px),
            );

            @each $value in $swiper-panel--slider-stories-config {
                $mq: map-get($value, 'mq');
                $width: map-get($value, 'width');
                $gutter: map-get($value, 'gutter');

                @include mq($mq) {
                    .swiper-panel--outer-wrapper {
                        width: $width + $gutter;
                    }

                    .swiper-panel--slide {
                        padding: 0 ($gutter / 2);
                    }

                    .swiper-panel--nav-btn {
                        &.swiper-panel--nav-btn__next {
                            transform: translateX($gutter / 2) translateX(-50%) translateY(-50%);
                        }

                        &.swiper-panel--nav-btn__prev {
                            transform: translateX($gutter / -2) translateX(50%) translateY(-50%);
                        }
                    }
                }
            }
        }

        &.swiper-panel__feed {
            $buttons-gutters: (
                (mq: '>lg', gutter: 24px, anothergutter: 50%),
                (mq: ('lg'), gutter: 16px, anothergutter: 50%),
                (mq: ('md'), gutter: 16px, anothergutter: 150%),
                (mq: 'sm', gutter: -24px, anothergutter: 250%),
                (mq: 'xs', gutter: -8px, anothergutter: 250%),
            );

            & {
                width: 100%;
                overflow: visible;
            }

            .swiper-panel--nav,
            .swiper-panel--outer-wrapper {
                width: 100%;
            }

            .swiper-panel--outer-wrapper {
                margin-bottom: 0;
            }

            .swiper-panel--nav {
                @include mq('<=md') {
                    display: flex;
                }
            }

            .swiper-panel--icon {
                @include mq('<=md') {
                    width: 24px;
                    height: 24px;
                }
            }

            .swiper-panel--nav-btn {
                background-color: $c--monochrome-silver;

                @include mq('<=md') {
                    padding: 8px;
                }

                @each $value in $buttons-gutters {
                    $button-mq: map-get($value, 'mq');
                    $button-gutter: map-get($value, 'gutter');
                    $vertical-button-gutter: map-get($value, 'anothergutter');

                    @include mq($button-mq) {
                        &.swiper-panel--nav-btn__next {
                            transform: translate(-100%, -50%) translate(-$button-gutter, -$vertical-button-gutter);
                        }

                        &.swiper-panel--nav-btn__prev {
                            transform: translate(100%, -50%) translate($button-gutter, -$vertical-button-gutter);
                        }
                    }
                }
            }

            .swiper-panel--pagination {
                display: none;
            }
        }
    }

    // States
}
