.form-server-error-message {
    // Vars
    // Support
    // Module
    & {
        display: block;
        border: 1px solid $c--signal-error;
        padding: 16px;
        color: $c--signal-error;
        margin-top: 24px;

        &:empty {
            display: none;
        }
    }

    // Facets
    // States
}
