.hover-image {
    // Vars
    // Support
    // Module
    & {
        position: relative;

        .hover-image--hover-image {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            will-change: opacity;
            transition: opacity 500ms;
        }
    }

    // Facets
    &.hover-image__active {
        .hover-image--hover-image {
            opacity: 1;
        }
    }
    // States
}
