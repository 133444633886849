.search-box {
    // Vars
    $search-box--contracted-size: 52px;
    $search-box--expanded-size: 360px;

    // Support

    // Module
    & {
        .search-box--form {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            overflow: hidden;
            transition: width $trs--base;
        }

        .search-box--btn {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            width: $search-box--contracted-size;
            height: $search-box--contracted-size;
        }

        .search-box--search-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .search-box--search-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: $c--monochrome-silver;
            border-radius: $search-box--contracted-size;
        }

        .search-box--input {
            @include type--paragraph-small;
            width: 220px;
            height: 100%;
            padding: 0 24px;
        }

        .search-box--search-btn {
            @include ff--base-medium;
            flex: 1 0 auto;
            padding-right: $search-box--contracted-size;
            font-size: 1.5rem;
            line-height: 1.33;
            color: $c--accent-blue;
            cursor: pointer;
            transition: color $trs--base;

            @include has-focus(true) {
                color: $c--brand-blue;
            }
        }
    }

    // Facets
    & {
        &.search-box__is-contracted {
            .search-box--form {
                width: $search-box--contracted-size;
            }
        }

        &.search-box__is-expanded {
            .search-box--form {
                width: $search-box--expanded-size;
            }
        }
    }

    // States
}
