&.grid-list__team {
    // Vars

    // Support

    // Module
    & {
        .grid-list--tabs {
            @include grid--span((ratio: 8 / 12, mq: '>md'));
            @include grid--span((ratio: 6 / 12, mq: 'md'));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));

            margin-bottom: $sz--inner-spacing-desktop;

            @include mq('<=md') {
                margin-bottom: $sz--inner-spacing-mobile;
            }
        }

        .grid-list--search {
            @include grid--span((ratio: 4 / 12, mq: '>md'));
            @include grid--span((ratio: 6 / 12, mq: 'md'));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));
        }

        .grid-list--item {
            @include grid--span((ratio: 1 / 2, mq: 'sm'));
            @include grid--span((ratio: 1 / 1, mq: 'xs'));
            overflow: hidden;

            @include mq('>sm') {
                &:nth-child(5n + 1), &:nth-child(5n + 2) {
                    @include grid--span((ratio: 1 / 2));
                }

                &:nth-child(5n + 3), &:nth-child(5n + 4), &:nth-child(5n + 5) {
                    @include grid--span((ratio: 1 / 3));
                }
            }

            @include mq('>md') {
                margin-bottom: $sz--inner-spacing-desktop;

                &:nth-child(5n + 2) {
                    margin: $sz--inner-spacing-desktop 0;
                }
            }

            @include mq('md') {
                margin-bottom: $sz--inner-spacing-mobile;

                &:nth-child(5n + 1) {
                    margin-bottom: $sz--inner-spacing-mobile;
                }

                &:nth-child(5n + 2) {
                    margin-top: $sz--inner-spacing-mobile;
                }
            }

            @include mq('<=sm') {
                margin-bottom: $sz--inner-spacing-mobile;
            }
        }
    }

    // Facets

    // States
}
