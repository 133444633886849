.expertises-link-list {
    // Vars
    // Support
    // Module
    & {
        .expertises-link-list--close {
            padding: 0 10px;
        }

        .expertises-link-list--close-btn {
            cursor: pointer;
            display: block;
        }

        .expertises-link-list--sub-title {
            margin-top: 24px;
        }

        .expertises-link-list--list {
            margin-top: 8px;
        }

        .expertises-link-list--expertise-link {
            display: block;
            padding: 6px 0;

            @include has-focus(true) {
                color: $c--accent-blue;
            }
        }

        .expertises-link-list--expertise-text {
            @include type--paragraph-small-reduced-lh;
        }
    }

    // Facets
    // States
}
