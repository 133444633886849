.mobile-nav-list-entry {
    // Vars
    --mobile-nav-list-entry--content-mobile-width: 360px;

    // Support

    // Module
    & {
        display: flex;
        flex-direction: column;

        .mobile-nav-list-entry--accordion-toggle {
            background-color: $c--monochrome-medium-gray;
            border-bottom: solid 1px $c--monochrome-steel;

            &.mobile-nav-list-entry--accordion-toggle__active {
                .mobile-nav-list-entry--accordion-toggle-content {
                    color: $c--accent-blue;
                }
            }
        }

        .mobile-nav-list-entry--accordion-toggle-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px 24px 16px 40px;
            max-width: 576px;
            margin: 0 auto;

            @include type--label;
        }

        .mobile-nav-list-entry--link-wrapper {
            background-color: $c--monochrome-medium-gray;
            border-bottom: solid 1px $c--monochrome-steel;

            &.mobile-nav-list-entry--link-wrapper__sub {
                background-color: $c--monochrome-white;
            }
        }

        .mobile-nav-list-entry--link-content {
            display: flex;
            align-items: center;
            padding: 16px 24px 16px 40px;
            width: 100%;
            max-width: 576px;
            margin: 0 auto;
        }

        .mobile-nav-list-entry--accordion-toggle-icon {
            transition: transform ease-in-out 200ms;
        }

        .mobile-nav-list-entry--sub {
            width: 100%;
        }

        .mobile-nav-list-entry--link.mobile-nav-list-entry--link.mobile-nav-list-entry--link {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            color: $c--brand-blue-dark;
            transition: color $trs--base;


            @include type--label;

            &.mobile-nav-list-entry--link__sub {
                @include type--label-small;

                font-weight: 400;
            }

            &.mobile-nav-list-entry--link__active {
                color: $c--accent-blue;
            }

            &:hover {
                color: $c--accent-blue;
            }
        }
    }

    // Facets
    & {
        &.mobile-nav-list-entry__open {
            .mobile-nav-list-entry--accordion-toggle-icon {
                transform: rotateZ(180deg);
            }
        }

        &.mobile-nav-list-entry__active {
            .mobile-nav-list-entry--link {
                color: $c--accent-blue;
            }
        }

        &.mobile-nav-list-entry__primary {
            .mobile-nav-list-entry--link {
                @include type--label-small;
            }
        }

        &.mobile-nav-list-entry__secondary {
            .mobile-nav-list-entry--link-wrapper {
                background-color: $c--monochrome-white;
            }

            .mobile-nav-list-entry--link.mobile-nav-list-entry--link {
                @include type--paragraph-small;
            }
        }
    }

    // States
}
