.business-value-spider {
    // Vars

    // Support

    // Module
    & {
        & {
            @include mq('>md') {
                margin: 80px 0;
            }

            @include mq('<=md') {
                margin: 60px 0;
            }
        }

        .business-value-spider--row {
            justify-content: center;
        }

        .business-value-spider--col {
            @include grid--span((ratio: 8 / 12));
            @include grid--span((ratio: 6 / 12, mq: '>lg'));
            @include grid--span((ratio: 12 / 12, mq: '<=md'));
        }

        .business-value-spider--title {
            margin-bottom: 16px;
        }

        .business-value-spider--description {
            @include type--paragraph-small;
        }

        .business-value-spider--img {
            margin: 32px 0 24px;
        }
    }

    // Facets

    // States
}
