.image-bleed {
    // Vars
    $xxl-container-size: map-get(nth($grid--base-container-sizes, 6), 'container-size');
    $xxl-container-edge: map-get(nth($grid--base-spacing, 6), 'edge');

    // Support
    @function image-bleed--find-grid-definition($target-mq, $container-sizes, $spacings) {
        $container-size: null;
        $spacing: null;

        @each $container-size-entry in $container-sizes {
            @if (map-get($container-size-entry, 'mq') == $target-mq) {
                $container-size: $container-size-entry;
            }
        }

        @each $spacing-entry in $spacings {
            @if (map-get($spacing-entry, 'mq') == $target-mq) {
                $spacing: $spacing-entry;
            }
        }

        @return map-assign($container-size, $spacing);
    }

    @mixin image-bleed--set-image-width($target-mq, $ratio) {
        $grid-definition: image-bleed--find-grid-definition($target-mq, $grid--base-container-sizes, $grid--base-spacing);
        $container-size: map-get($grid-definition, 'container-size');
        $gutter: map-get($grid-definition, 'gutter');
        $edge: map-get($grid-definition, 'edge');

        @include mq($target-mq, 'screen') {
            $content-width: ($container-size - (2 * $edge));
            $image-width: ((($content-width + $gutter) * ($ratio)) - $gutter);

            width: calc(((100vw - #{$content-width}) / 2) + #{$image-width}); // stylelint-disable-line function-calc-no-invalid
        }
    }

    // Module
    & {
        $grid-definition: image-bleed--find-grid-definition('xxl', $grid--base-container-sizes, $grid--base-spacing);

        @include grid--constrain($grid-definition);

        .image-bleed--container {
            margin-left: auto;
            margin-right: auto;
        }

        .image-bleed--outer-wrapper {
            @include mq('screen') {
                z-index: 0;
                position: relative;
                display: flex;
            }

            @include mq('>md', 'screen') {
                flex-flow: row nowrap;
                width: 200%;
            }

            @include mq('<=md') {
                flex-flow: column nowrap;
            }
        }

        .image-bleed--content-wrapper {
            z-index: 1;
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            @include mq('>md', 'screen') {
                top: 0;
                width: 50%;
            }

            @include mq('<=md') {
                position: relative;
            }
        }

        .image-bleed--image-wrapper {
            display: flex;
            flex-flow: row nowrap;
            width: 100%;

            @include mq('>md') {
                width: 50%;
            }

            @include mq('print') {
                display: none;
            }
        }

        .image-bleed--image-spanner {
            @include image-bleed--set-image-width('md', 10 / 12);
            @include image-bleed--set-image-width('sm', 1 / 1);

            > img {
                display: block;
                width: 100%;
            }

            @include mq('xs') {
                width: calc(100vw - 16px);
            }
        }

        .image-bleed--animated-img {
            object-fit: cover;
        }

        .image-bleed--row {
            @include mq('<=md') {
                justify-content: flex-end;
            }
        }

        .image-bleed--col {
            @include grid--span((mq: 'md', ratio: 10 / 12));
            @include grid--span((mq: '<=sm', ratio: 1 / 1));
        }

        .image-bleed--image {
            display: block;
            width: 100%;
        }
    }

    // Facets
    & {
        &.image-bleed__moving.image-bleed__moving {
            .image-bleed--col {
                @include grid--span((mq: 'md', ratio: 12 / 12));
            }

            .image-bleed--image-spanner {
                @include mq('<=md') {
                    width: 100%;
                }

            }
        }

        // Facet: Base
        &.image-bleed__base {
            align-items: center;

            .image-bleed--col {
                @include grid--span((mq: '>md', ratio: 5 / 12));
                @include grid--span((mq: 'print', ratio: 12 / 12));
            }

            .image-bleed--image-spanner {
                @include mq('>md') {
                    width: 50%;
                }

                @include mq('print') {
                    width: 100%;
                }
            }
        }

        // Facet: List expert random
        &.image-bleed__list-expert-random {
            align-items: center;

            .image-bleed--col {
                @include grid--span((mq: '>md', ratio: 5 / 12));
                @include grid--span((mq: '<=md', ratio: 12 / 12));
                @include grid--span((mq: 'print', ratio: 12 / 12));
            }

            .image-bleed--content-wrapper {
                @include mq('<=md') {
                    order: 1;
                }
            }

            .image-bleed--image-wrapper {
                @include mq('<=md') {
                    order: 3;
                }
            }

            .image-bleed--image-spanner {
                @include mq('>md') {
                    width: 50%;
                }

                @include mq('<=md') {
                    width: 100%;
                }

                @include mq('print') {
                    width: 100%;
                }
            }
        }

        // Facet: Hero
        &.image-bleed__hero {
            align-items: center;

            .image-bleed--col {
                @include grid--span((mq: '>lg', ratio: 7 / 12));
                @include grid--span((mq: 'lg', ratio: 8 / 12));
                @include grid--span((mq: 'md', ratio: 8 / 12));
                @include grid--span((mq: 'print', ratio: 12 / 12));
            }

            .image-bleed--image-spanner {
                @include image-bleed--set-image-width('xxl', 2 / 3);
                @include image-bleed--set-image-width('xl', 2 / 3);
                @include image-bleed--set-image-width('lg', 2 / 3);
                @include grid--span((mq: 'print', ratio: 12 / 12));
            }

            .image-bleed--content-col {
                @include grid--span((mq: '>md', ratio: (1 / 2)));
                @include grid--span((mq: 'print', ratio: (1 / 1)));

                @include mq('>md', 'screen') {
                    width: 100%;
                    max-width: 50%;
                }
            }

            .image-bleed--title-col {
                display: flex;
            }

            .image-bleed--content-row {
                flex-direction: column;
            }

            &.image-bleed__comp-header {
                .image-bleed--image-spanner {
                    @include mq('xxl') {
                        // The width is calculated out of the xxl container size and is equal to 8 cols plus the edge
                        width: ($xxl-container-size - $xxl-container-edge * 2) / 12 * 8 + $xxl-container-edge;
                    }
                }
            }
        }

        // Facet: Color
        &.image-bleed__color {
            align-items: center;

            .image-bleed--col {
                @include grid--span((mq: '>md', ratio: 5 / 12));
            }

            .image-bleed--content-wrapper {
                @include mq('>md') {
                    width: 25%;
                }

                @include mq('<=md') {
                    padding-top: 0;
                }
            }

            .image-bleed--image-spanner {
                display: flex;
                justify-content: center;
                align-items: center;

                @include mq('>md', 'screen') {
                    width: 50%;
                }

                @include mq('<=md', 'screen') {
                    width: 100%;
                }
            }
        }

        // Facet: Leftright
        &.image-bleed__leftright {
            .image-bleed--col {
                @include grid--span((mq: '>md', ratio: 5 / 12));
            }

            .image-bleed--image-spanner {
                @include mq('>md') {
                    width: 50%;
                }
            }

            .image-bleed--container {
                margin-top: 0;
            }
        }

        // Facet: List-link
        &.image-bleed__list-link,
        &.image-bleed__team-detail {
            .image-bleed--col {
                @include grid--span((mq: '>md', ratio: 5 / 12));
            }

            .image-bleed--image-spanner {
                @include mq('>md', 'screen') {
                    width: 50%;
                }
            }

            .image-bleed--container {
                margin-top: 0;
            }
        }
    }

    // States
    & {
        // Alignment left
        &.image-bleed__align-left {
            .image-bleed--content-wrapper {
                @include mq('>md', 'screen') {
                    left: -50%;
                }
            }

            .image-bleed--image-wrapper {
                justify-content: flex-end;
            }
        }

        // Alignment right
        &.image-bleed__align-right {
            .image-bleed--content-wrapper {
                @include mq('>md', 'screen') {
                    left: -25%;
                }
            }

            .image-bleed--image-wrapper {
                justify-content: flex-start;
            }

            // Facet: List-link
            &.image-bleed__list-link,
            &.image-bleed__team-detail {
                .image-bleed--content-wrapper {
                    @include mq('>md', 'screen') {
                        left: -50%;
                    }
                }

                .image-bleed--row {
                    justify-content: flex-start;
                }

                .image-bleed--col {
                    @include grid--push((mq: '>md', ratio: 7 / 12));
                    @include grid--span((mq: '>md', ratio: 5 / 12));
                }
            }

            &.image-bleed__team-detail {
                .image-bleed--col {
                    @include grid--push((mq: 'md', ratio: 2 / 12));
                    @include grid--span((mq: 'md', ratio: 8 / 12));
                }
            }
        }
    }
}
