.page-header {
    // Vars

    // Support

    // Module
    & {
        @include mq('>md') {
            margin-bottom: 80px;
        }

        @include mq('<=md') {
            margin-bottom: 40px;
        }
    }

    // Facets

    // States
}
