.slider-stories-entry {
    // Vars

    // Support

    // Module
    & {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        background-color: $c--monochrome-white;
        border-radius: 9px;

        .slider-stories-entry--image-wrapper {
            overflow: hidden;
        }

        .slider-stories-entry--image {
            position: relative;
            display: block;
            width: 100%;
            transition: transform $trs--base;
        }

        .slider-stories-entry--content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: space-between;
            padding: 32px 32px 16px;
        }

        .slider-stories-entry--title {
            margin-bottom: 8px;
            transition: color $trs--base;
        }

        .slider-stories-entry--label {
            @include type--paragraph-small;
            display: block;
            margin-bottom: 8px;
            color: $c--accent-pink;
        }

        .slider-stories-entry--icon {
            position: relative;
            margin-top: 8px;
            transition: transform $trs--base;
        }
    }

    // Facets

    // States
    & {
        & {
            @include has-focus(true) {
                .slider-stories-entry--title {
                    color: $c--label-link;
                }

                .slider-stories-entry--image {
                    transform: scale(1.1);
                }

                .slider-stories-entry--icon {
                    transform: translateX(16px);
                }
            }
        }
    }
}
