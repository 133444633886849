.tabs-tab {
    // Vars

    // Support

    // Module
    & {
        & {
            @include type--tabs;
            display: flex;
            align-items: center;
            border-bottom: 2px solid transparent;
            cursor: pointer;

            @include mq('<=sm') {
                white-space: nowrap;
            }

            @include has-focus(true) {
                color: $c--accent-blue;

                @include mq('>lg') {
                    border-color: $c--accent-blue;
                }
            }
        }
    }

    // Facets
    &.tabs-tab__active {
        color: $c--accent-blue;

        @include mq('>lg') {
            border-color: $c--accent-blue;
        }
    }

    // States
}
