.list-link {
    // Vars

    // Support

    // Module
    & {
        .list-link--entry ~ .list-link--entry {
            margin-top: 32px;
        }
    }

    // Facets
    & {
        &.list-link__list-media {
            .list-link--entry {
                border-bottom: 1px solid rgba($c--brand-blue-dark, 0.2);

                &:first-child {
                    border-top: 1px solid rgba($c--brand-blue-dark, 0.2);
                }

                ~ .list-link--entry {
                    margin-top: 0;
                }
            }
        }
    }

    // States
}
