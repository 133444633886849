.mobile-nav-trigger {
    // Vars

    // Support

    // Module
    & {
        & {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        .mobile-nav-trigger--text {
            @include type--label-small;
            margin-right: 8px;
            padding-left: 8px;
        }
    }

    // Facets

    // States
}
