// stylelint-disable plugin/selector-bem-pattern

[data-aos='card-colored'] {
    opacity: 0;
    transform: translateY(150px);
    transition-property: transform, opacity;


    .card-colored-item--content-background {
        transform: translateX(-100%);
        transition: transform 400ms ease;
    }

    &.aos-animate {
        opacity: 1;
        transform: translateY(0);

        .card-colored-item--content-background {
            transform: translateX(0);
        }
    }
}
