.contact-expert {
    // Vars
    $item-spacing: 32px;
    $item-spacing-mobile: 16px;

    // Support

    // Module
    & {
        .contact-expert--title {
            margin-bottom: 32px;
            color: $c--brand-blue-dark;
            text-align: center;
        }

        .contact-expert--text {
            @include type--paragraph;
            margin-bottom: 32px;
            justify-content: center;
            text-align: center;
        }

        .contact-expert--wrapper {
            justify-content: center;
        }

        .contact-expert--outer-item {
            @include grid--span((ratio: 2 / 3, mq: 'xxl'));
            @include grid--span((ratio: 10 / 12, mq: 'xl'));
            @include grid--span((ratio: 1 / 1, mq: 'lg'));
            @include grid--span((ratio: 10 / 12, mq: 'md'));
            @include grid--span((ratio: 1 / 1, mq: '<=sm'));
            @include grid--span((ratio: 1 / 1, mq: 'print'));
        }

        .contact-expert--item {
            @include grid--span((ratio: 1 / 2, mq: '>md'));
            @include grid--span((ratio: 1 / 1, mq: '<=md'));
            @include grid--span((ratio: 1, mq: 'print'));

            &:only-child {
                // NOTE: Nested grid: (inner grid) / (outer grid)
                @include grid--span((ratio: (1 / 2) / (2 / 3), mq: 'xxl'));
                @include grid--span((ratio: (1 / 2) / (10 / 12), mq: 'xl'));
                @include grid--span((ratio: (1 / 2) / (1 / 1), mq: 'lg'));
                @include grid--push((ratio: (1 / 8), mq: 'xxl'));
                @include grid--push((ratio: (1 / 5), mq: 'xl'));
                @include grid--push((ratio: (1 / 4), mq: 'lg'));
            }

            & ~ .contact-expert--item {
                margin-top: $item-spacing;

                @include mq('<=sm') {
                    margin-top: $item-spacing-mobile;
                }
            }

            @include mq('>md') {
                &:nth-child(-n+2) {
                    margin-top: 0;
                }
            }
        }
    }

    // Facets
    // States
}
