.navigation-meta {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'nav-row', cols: ('nav-col')));
        @include grid--space((gutter: 16px, row: 'nav-row', cols: ('nav-col')));

        padding: 24px 0 16px;
        background-color: $c--monochrome-white;

        @include mq('<=md') {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 0;
            width: 100%;
            height: $sz--navigation-meta-height-mobile;
            background-color: $c--monochrome-white;

            @include mq('print') {
                height: $sz--navigation-meta-height-desktop;
            }
        }

        .navigation-meta--nav {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-items: center;
            flex: 1 0 auto;

            &.navigation-meta--nav__desktop {
                @include mq('<=md') {
                    display: none;
                }
            }

            &.navigation-meta--nav__mobile {
                @include mq('>md') {
                    display: none;
                }
            }
        }

        .navigation-meta--home-link {
            display: block;
        }

        .navigation-meta--logo {
            display: block;

            @include mq('<=md') {
                max-height: 40px;
            }

            @include mq('print') {
                max-height: 80px;
            }
        }

        .navigation-meta--contact-btn {
            @include ff--base-medium;
            padding: 15px 40px 14px;
            font-size: 1.5rem;
            line-height: 1.33;
        }

        .navigation-meta--nav-col {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            height: 52px;
        }
    }

    // Facets

    // States
}
