.list-story-entry {
    // Vars

    // Support

    // Module
    & {
        .list-story-entry--image {
            display: block;
            width: 100%;
        }

        .list-story-entry--content {
            margin-top: 16px;
        }

        .list-story-entry--label {
            @include type--paragraph-small;
            color: $c--accent-pink;
        }

        .list-story-entry--title {
            @include type--label-small;
            margin-top: 8px;
        }

        .list-story-entry--description {
            @include type--paragraph-small-reduced-lh;
            margin-top: 2px;
        }

        .list-story-entry--tags {
            margin-top: 16px;
        }
    }

    // Facets

    // States
}
