.flyout-item {
    position: relative;

    .flyout-item--content {
        width: 593px;
    }

    .flyout-item--content-wrapper {
        pointer-events: none;
        position: absolute;
        padding-top: 25px;
        top: calc(100% - 24px);
        transition: max-height ease-in 200ms;
        max-width: 593px;
        overflow: hidden;
    }

    &.flyout-item__interactive {
        .flyout-item--content-wrapper {
            pointer-events: initial;
        }
    }
}
