.anchor-link {
    // Vars

    // Support

    // Module
    & {
        @include type--paragraph;
        display: inline-flex;
        flex-flow: column nowrap;
        align-items: center;
        transition: color $trs--base;

        .anchor-link--icon {
            display: block;
            margin-top: 2px;
        }
    }

    // Facets

    // States
    & {
        // NOTE: Increase selector weight to style the color on the root element
        &.anchor-link {
            @include has-focus(default) {
                color: $c--accent-blue;
            }

            @include has-focus(true) {
                color: $c--brand-blue;
            }
        }
    }
}
