/// Secondary
///
@mixin btn--outline {
    // Vars

    // Support

    // Module

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--outline-base;
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--outline-focus;
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': default, 'active': true)) {
            @include btn--outline-active;
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default)) {
            @include btn--outline-disabled;
        }
    }
}

/// Base
///
@mixin btn--outline-base {
    color: $c--brand-blue-dark;
    background-color: transparent;
    border-color: $c--brand-blue-dark;
}

/// Focus
///
@mixin btn--outline-focus {
    color: $c--brand-blue;
    border-color: $c--brand-blue;
}

/// Active
///
@mixin btn--outline-active {
    color: $c--brand-blue;
    border-color: $c--brand-blue;
}

/// Disabled
///
@mixin btn--outline-disabled {
    color: $c--monochrome-light-gray;
}
