.list-escalator {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        overflow: hidden;

        @include mq('>lg') {
            height: $sz--list-escalator-height-x-large;
        }

        @include mq('lg') {
            height: $sz--list-escalator-height-large;
        }

        @include mq('<=md') {
            height: $sz--list-escalator-height-medium;
        }

        .list-escalator--items {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    // Facets

    // States
}
