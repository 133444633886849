&.grid-list__story-teaser {
    .grid-list--tabs {
        @include grid--span((ratio: 12 / 12));

        margin-bottom: $sz--inner-spacing-desktop;

        @include mq('<=md') {
            margin-bottom: $sz--inner-spacing-mobile;
        }
    }

    .grid-list--item {
        @include grid--span((ratio: 1 / 1, mq: '<=sm'));
        overflow: hidden;

        @include mq('>sm') {
            margin-bottom: $sz--inner-spacing-desktop;

            @include grid--span((ratio: 1 / 2));
        }

        @include mq('<=sm') {
            margin-bottom: $sz--inner-spacing-desktop;
            margin-top: 0;
        }

        @include mq('md') {
            margin-bottom: $sz--inner-spacing-mobile;
        }
    }
}
