.expandable-button {
    // Vars

    // Support

    // Module
    & {
        & {
            z-index: z-index(expandable-button);
            background-color: $c--monochrome-white;
            display: inline-block;
            border-radius: 50px;
            box-shadow: $shadow-level-2;
        }

        .expandable-button--input {
            display: none;
        }

        .expandable-button--icon {
            &.expandable-button--icon__closed {
                display: none;
            }
        }

        .expandable-button--label {
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            width: 100%;
            padding: 6px;
            cursor: pointer;
            user-select: none;
        }

        .expandable-button--wrapper {
            display: none;
        }
    }

    // Facets

    // States
    & {
        .expandable-button--input:checked {
            ~ .expandable-button--wrapper {
                display: block;
            }

            ~ .expandable-button--label .expandable-button--icon {
                &.expandable-button--icon__open {
                    display: none;
                }

                &.expandable-button--icon__closed {
                    display: block;
                }
            }
        }
    }
}
