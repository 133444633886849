////
/// Content types (p)
////

@mixin type--button {
    @include ff--base-medium;
    font-size: 1.7rem;
    line-height: 1.29;

    @include mq('<=lg') {
        font-size: 1.5rem;
        line-height: 1.33;
    }
}

@mixin type--ordered-list-counter {
    @include ff--base-bold;
    font-size: 1.7rem;
    line-height: 1.59;
    font-feature-settings: 'tnum' on, 'lnum' on;

    @include mq('<=lg') {
        font-size: 1.5rem;
        line-height: 1.6;
    }
}

@mixin type--facts-image-number {
    @include ff--base-bold;
    font-size: 7.2rem;
    line-height: 1;
    font-feature-settings: 'tnum' on, 'lnum' on;

    @include mq('<=lg') {
        font-size: 5.1rem;
        line-height: 1.294;
    }

    @include mq('<=sm') {
        font-size: 4rem;
        line-height: 1;
    }
}

@mixin type--list-number-number {
    @include ff--base-bold;
    font-size: 5.1rem;
    line-height: 1.29;
    font-feature-settings: 'tnum' on, 'lnum' on;
}

@mixin type--chart-number {
    @include ff--base-bold;
    font-size: 5.1rem;
    line-height: 6.6rem;
}

@mixin type--lead {
    @include ff--base-regular;
    font-size: 2.4rem;
    line-height: 1.5;

    @include mq('<=lg') {
        font-size: 1.9rem;
        line-height: 1.47;
    }
}

@mixin type--label {
    @include ff--base-bold;
    font-size: 1.7rem;
    line-height: 1.29;
    font-feature-settings: 'tnum' on, 'lnum' on;

    @include mq('<=lg') {
        font-size: 1.5rem;
        line-height: 1.6;
    }
}

@mixin type--form-label {
    @include ff--base-bold;
    font-size: 1.4rem;
    line-height: 1.29;
    font-feature-settings: 'tnum' on, 'lnum' on;
}

@mixin type--form-text {
    @include ff--base-regular;
    font-size: 1.4rem;
    line-height: 1.29;
}

@mixin type--dropdown-label {
    @include ff--base-medium;
    font-size: 1.5rem;
    line-height: 1.2;
}

@mixin type--label-small {
    @include ff--base-bold;
    font-size: 1.4rem;
    line-height: 1.29;
    font-feature-settings: 'tnum' on, 'lnum' on;

    @include mq('<=lg') {
        font-size: 1.3rem;
        line-height: 1.31;
    }
}

@mixin type--paragraph {
    @include ff--base-regular;
    font-size: 1.7rem;
    line-height: 1.59;

    @include mq('<=lg') {
        font-size: 1.5rem;
        line-height: 1.6;
    }
}

@mixin type--paragraph-small {
    @include ff--base-regular;
    font-size: 1.4rem;
    line-height: 1.57;

    @include mq('<=lg') {
        font-size: 1.3rem;
        line-height: 1.62;
    }
}

@mixin type--paragraph-small-reduced-lh {
    @include type--paragraph-small;
    line-height: 1.29;

    @include mq('<=lg') {
        line-height: 1.31;
    }
}

@mixin type--paragraph-x-small {
    @include ff--base-regular;
    font-size: 1.2rem;
    line-height: 1.67;
}

@mixin type--tabs {
    @include ff--base-bold;
    font-size: 1.4rem;
    line-height: 1.29;
    font-feature-settings: 'tnum' on, 'lnum' on;

    @include mq('<=lg') {
        font-size: 1.1rem;
        line-height: 1.27;
    }
}

@mixin type--breadcrumb {
    font-size: 1.5rem;
    line-height: 1.46;
}
