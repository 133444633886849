// stylelint-disable unit-blacklist, plugin/selector-bem-pattern, declaration-no-important

////
/// Support styles, to make a print version more useful
////

@page {
    size: A4; // stylelint-disable-line value-keyword-case
    margin: 25mm;
}

@media print {
    html, body {
        width: 210mm;
        height: 297mm;
    }

    /* Defining all page breaks */
    a {
        page-break-inside: avoid;
    }

    blockquote {
        page-break-inside: avoid;
    }

    h1, h2, h3, h4, h5, h6 {
        page-break-after: avoid;
        page-break-inside: avoid;
    }

    img {
        page-break-inside: avoid;
        page-break-after: avoid;
    }

    table, pre {
        page-break-inside: avoid;
    }

    ul, ol, dl {
        page-break-before: avoid;
    }

    /* Displaying link color and link behaviour */
    a[href^='http']::after {
        content: '<' attr(href) '>';
    }

    a::after > img {
        content: '';
    }

    a[href^='#']::after {
        content: '';
    }

    /* Hiding unnecessary elements for the print */
    .chatbot,
    .navigation-sub-mobile,
    .navigation-meta--nav,
    .footer-social-media,
    .contact-expert,
    .grid-list--tabs,
    .page-sharer,
    .navigation-controller--back-button,
    .navigation-main--row,
    .navigation-controller--sub-nav,
    .footer-meta,
    .comp-list-link,
    .list-numbers,
    .comp-header--anchor,
    .form-gated,
    .cookie-monster-banner {
        display: none !important;
    }
}
