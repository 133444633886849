.language-chooser {
    // Vars
    $mobile-top-offset: 84px;
    $default-top-offset: 28px;
    $big-top-offset: 238px;

    // Support

    // Module
    & {
        &.language-chooser {
            box-shadow: none;
            background: $c--monochrome-silver;
            align-self: end;
            margin: 0 16px;
        }

        .language-chooser--item {
            padding: 2px 6px;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 6px;
            }
        }

        .language-chooser--link {
            cursor: pointer;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            padding: 10px;
            margin: 0;
            text-transform: uppercase;
            border-radius: 50%;

            @include type--button;

            &:not(.language-chooser--toggle):not(.language-chooser--link__active) {
                @include has-focus(true) {
                    color: $c--brand-blue-dark;
                }

                &:hover {
                    background: $c--monochrome-medium-gray;
                }
            }

            &.language-chooser--link__active {
                color: $c--monochrome-white;
                background: $c--brand-blue-dark;
            }
        }

        .language-chooser--toggle {
            &:hover {
                color: $c--accent-blue;
            }
        }
    }

    // Facets

    & {
        &.language-chooser__mobile {
            box-shadow: $shadow-level-2;
            background: $c--monochrome-white;
            display: flex;
            flex-direction: column-reverse;
            margin: 0;

            .language-chooser--item {
                padding: 2px 6px;

                &:first-child {
                    padding-top: 6px;
                }

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }

    // States
}
