.background-pattern-list {
    // Vars
    $pattern-ratio: 1080 / 1440; // 0.75

    // Support

    // Module
    & {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .background-pattern-list--element {
            position: relative;
            width: 100%;
            padding-top: 100% * $pattern-ratio;
        }

        .background-pattern-list--pattern {
            @include overlay;
        }
    }

    // Facets
    & {
        &.background-pattern-list__is-ready {
            display: block;
        }
    }

    // States
}
