@mixin section-color-bar($selector) {
    @each $color-key, $color-value in $backgrounds {
        &.#{$selector}__accent-#{$color-key} {
            border-color: $color-value;
        }
    }

    &.#{$selector}__monochrome-silver {
        border-color: $c--monochrome-silver;
    }

    &.#{$selector}__none {
        border-color: transparent;
    }
}
