.comp-leftright-color {
    // Vars

    // Support
    @mixin comp-leftright-color--colorize($color) {
        @include mq('>md', 'screen') {
            background-color: $color;
        }

        .comp-leftright-color--inner-wrapper {
            @include mq('md', 'screen') {
                background-color: $color;
            }
        }

        .comp-leftright-color--outer-wrapper {
            @include mq('<=sm', 'screen') {
                background-color: $color;
            }
        }
    }

    // Module
    & {
        @include grid--root((mq: '<=md'));
        @include grid--constrain(list-get-map-where($grid--base-container-sizes, 'mq', 'xs'));
        @include grid--constrain(list-get-map-where($grid--base-container-sizes, 'mq', 'sm'));
        @include grid--constrain(list-get-map-where($grid--base-container-sizes, 'mq', 'md'));
        @include grid--space(list-get-map-where($grid--base-spacing, 'mq', 'xs'));
        @include grid--space(list-get-map-where($grid--base-spacing, 'mq', 'sm'));
        @include grid--space(list-get-map-where($grid--base-spacing, 'mq', 'md'));

        .comp-leftright-color--description {
            margin-top: 16px;

            @include mq('<=md') {
                margin-top: 12px;
            }
        }

        .comp-leftright-color--action {
            display: flex;
            margin-top: 24px;

            @include mq('print') {
                display: none;
            }
        }

        .comp-leftright-color--container {
            &.comp-leftright-color--container__content {
                @include mq('>md', 'screen') {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    width: 100%;
                }
            }

            &.comp-leftright-color--container__image {
                @include mq('<=sm', 'screen') {
                    padding: 0;
                    max-width: none;
                }
            }
        }

        .comp-leftright-color--inner-wrapper {
            @include mq('>md', 'screen') {
                padding: 60px 0;
            }

            @include mq('md', 'screen') {
                padding: 40px 0;
            }

            @include mq('<=sm', 'screen') {
                padding: 24px 0;
            }
        }

        .comp-leftright-color--inner-wrapper {
            @include mq('>lg', 'screen') {
                width: 480px;
            }

            @include mq('lg', 'screen') {
                width: 392px;
            }
        }

        .comp-leftright-color--col {
            @include grid--span((mq: 'md', ratio: 8 / 12));
            @include grid--push((mq: 'md', ratio: 2 / 12));
            @include grid--span((mq: '<=sm', ratio: 1 / 1));
            @include grid--span((mq: 'print', ratio: 1 / 1));
        }
    }

    // Facets
    & {
        // Accent colors
        & {
            @include section-background($selector: 'comp-leftright-color');
        }
    }

    // States
}
