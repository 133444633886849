.pagination {
    // Vars

    // Support

    // Module
    & {
        padding: 0 0 80px;

        @include mq('<=lg') {
            padding: 0 0 60px;
        }

        .pagination--layout-row {
            justify-content: center;
        }

        .pagination--layout-col {
            @include grid--span((ratio: 1 / 1));
            @include grid--span((ratio: 8 / 12, mq: 'lg'));
            @include grid--span((ratio: 1 / 2, mq: '>lg'));
        }

        .pagination--list {
            //
        }

        .pagination--item {
            display: inline-block;
            margin: 0;

            & ~ .pagination--item {
                margin-left: 16px;
            }
        }

        .pagination--link {
            display: block;
            border-radius: 50%;
            background: $c--monochrome-silver;
            width: 40px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            color: $c--brand-blue-dark;
        }

        .pagination--link__active {
            color: $c--monochrome-white;
            background: $c--accent-blue;
        }
    }
    // Facets
    // States
}
