/// Field styles
///
@mixin ui-form-field--primary-all {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-flow: row wrap;
        width: 100%;

        @include mq('<=md') {
            flex-flow: column nowrap;
        }

        .ui-form-field--input-container {
            position: relative;
            display: flex;

            @include mq('>md') {
                flex: 1 1 100%;
            }
        }

        .ui-form-field--title-label {
            @include ff--base-regular;
            display: flex;
            margin-bottom: 4px;
            font-size: 1.2rem;
            line-height: 1.3;
        }
    }

    // States
    & {
        // Base
        @include ui-form-field--apply-states(()) {
            @include ui-form-field--primary-all-base;
        }

        // Required
        @include ui-form-field--apply-states(('required': true)) {
            @include ui-form-field--primary-all-required;
        }
    }
}

/// Base
///
@mixin ui-form-field--primary-all-base {
    .ui-form-field--title-label {
        @include type--form-label;
        color: $c--label-text;

        @include mq('>md') {
            padding-top: 16px;
            flex: 0 0 188px;
        }
    }

    .ui-form-field--input {
        @include type--form-text;
    }
}

/// Required
///
@mixin ui-form-field--primary-all-required {
    .ui-form-field--title-label {
        @include ui-form-field--is-required;
    }
}
