.section-search {
    // Vars

    // Support

    // Module
    & {
        @include section-background($selector: 'section-search', $add-section-padding: true);

        .section-search--row {
            justify-content: center;
        }

        .section-search--col {
            @include grid--span((ratio: 1 / 1));
            @include grid--span((ratio: 8 / 12, mq: 'lg'));
            @include grid--span((ratio: 1 / 2, mq: '>lg'));
        }

        .section-search--title {
            margin-bottom: 36px;
        }

        .section-search--input-wrapper {
            position: relative;
        }

        .section-search--submit,
        .section-search--cancel {
            position: absolute;
            top: 50%;
            right: 5px;
            transform: translateY(-50%);
            cursor: pointer;
        }

        .section-search--cancel {
            right: 64px;
        }

        .section-search--input {
            // TODO: move this to shared form styles, if visually identical..
            @include type--paragraph-small;
            padding: 16px 120px 16px 16px;
            height: 52px;
            line-height: 52px;
            border: 1px solid #9fa8be;
            width: 100%;
            background: $c--monochrome-white;
            color: $c--brand-blue-dark;
        }
    }
    // Facets
    // States
}
