&.page__no-offset {
    // Vars
    $sz--page-offset-large: 80px;
    $sz--page-offset-medium: 60px;
    $sz--navigation-height-desktop-xl: $sz--navigation-meta-height-desktop + $sz--navigation-main-height-desktop-xl;
    $sz--navigation-height-desktop-lg: $sz--navigation-meta-height-desktop + $sz--navigation-main-height-desktop-lg;
    $sz--navigation-height-mobile: $sz--navigation-meta-height-mobile;

    // Support

    // Module
    & {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        flex: 1 0 auto;

        .page--main {
            @include mq('>lg', 'screen') {
                padding-top: $sz--navigation-height-desktop-xl;
            }

            @include mq('lg', 'screen') {
                padding-top: $sz--navigation-height-desktop-lg;
            }

            @include mq('<=md', 'screen') {
                padding-top: $sz--navigation-height-mobile;
            }
        }
    }

    // Facets
    & {
        &.page__has-sub-nav {
            .page--main {
                @include mq('>lg', 'screen') {
                    padding-top: $sz--navigation-height-desktop-xl + $sz--page-offset-large;
                }

                @include mq('lg', 'screen') {
                    padding-top: $sz--navigation-height-desktop-lg + $sz--page-offset-large;
                }

                @include mq('md', 'screen') {
                    padding-top: $sz--navigation-height-desktop-lg + $sz--page-offset-large;
                }

                @include mq('<=md', 'screen') {
                    padding-top: $sz--navigation-height-mobile + $sz--page-offset-medium;
                }
            }
        }

        &.page__has-back-button {
            .page--main {
                @include mq('>md', 'screen') {
                    padding-top: 120px;
                }

                @include mq('md', 'screen') {
                    padding-top: 120px;
                }

                @include mq('<=md', 'screen') {
                    padding-top: 120px;
                }
            }
        }
    }

    // States
}
