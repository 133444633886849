////
/// Global z-index registry to keep track of the global layer hierarchy
////

/// A list of names with their corresponding layer hierarchy, used as `z-index` value
///
$layers: (
    content: 0,
    'navigation-controller': 2,
    'navigation-sub-mobile': 2,
    'autocomplete-search-dropdown': 2,
    'overlay': 3,
    'back-button': 3,
    'chatbot': 3,
    'mobile-nav-overlay': 4,
    'media-overlay': 4,
    'expertises-overlay': 4,
    'cookie-banner': 5,
    'comp-header-content': 1,
    'expandable-button': 10,
    'page-sharer': 10,
    'breadcrumbs': 10,
);

/// Returns a z-index stored within the `$layers` map, which stores z-indexes of all central ui elements
/// @param {string} $layer - Key that is searched for
/// @return {number} A z-index number
///
@function z-index($layer) {
    @if (not map-has-key($layers, $layer)) {
        @error 'Layer with name "#{$key}" was not found in the `$layers` map.';
    }

    @return map-get($layers, $layer);
}

