.rich-text-plugin {
    // Vars

    // Support

    // Module
    & {
        .rich-text-plugin--row {
            justify-content: center;
        }

        .rich-text-plugin--col {
            @include grid--span((ratio: 8 / 12));
            @include grid--span((ratio: 6 / 12, mq: '>lg'));
            @include grid--span((ratio: 12 / 12, mq: '<=md'));
        }

        .rich-text-plugin--actions-col {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        .rich-text-plugin--actions {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            margin-top: 24px;
        }

        &.rich-text-plugin {
            &.rich-text-plugin {
                @include richtext-lists;
            }
        }
    }

    // Facets
    & {
        @include section-background($selector: 'rich-text-plugin', $add-section-padding: true);
    }

    // States
}
