.list-expert-random-static-list {
    // Vars

    // Support

    // Module
    & {
        margin-top: 24px;

        @include mq('>md') {
            display: none;
        }

        .list-expert-random-static-list--col {
            @include grid--span((ratio: 1 / 1));
        }

        .list-expert-random-static-list--more-col {
            @include grid--span((ratio: 1 / 2));
            display: flex;
            justify-content: center;
            color: mix($c--brand-blue-dark, $c--monochrome-white, 20%);
        }

        .list-expert-random-static-list--more-icon {
            margin: 16px 0;
        }
    }

    // Facets

    // States
}
