.picture-element {
    // Vars

    // Support

    // Module
    & {
        display: block;
        width: 100%;
        height: auto;
        transition: filter $trs--base;
    }

    // Facets
    & {
        &.picture-element__is-blurred {
            @include mq('>sm') {
                filter: blur(12px);

                // stylelint-disable-next-line media-feature-name-no-vendor-prefix
                @media screen and (-webkit-min-device-pixel-ratio: 0) {
                    filter: none;
                }
            }
        }
    }

    // States
}
