.card-person {
    // Vars
    $sz--image-width-x-large: 274px;
    $sz--image-width-large: 210px;


    // Support

    // Module
    & {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        transition: $trs--base color;

        @include mq('<=md') {
            flex-flow: column nowrap;
            align-items: stretch;
        }

        @include has-focus(true) {
            color: $c--accent-blue;
        }

        .card-person--image {
            display: flex;
            overflow: hidden;

            @include mq('>lg') {
                width: $sz--image-width-x-large;
                height: $sz--list-escalator-height-x-large / 4;
            }

            @include mq('lg') {
                width: $sz--image-width-large;
                height: $sz--list-escalator-height-large / 4;
            }

            @include mq('<=md') {
                @include grid--span((ratio: 1 / 2));
                display: block;
                height: 100%;
            }
        }

        .card-person--image-el {
            display: block;
            width: 100%;
        }

        .card-person--content {
            padding-left: 32px;

            @include mq('<=md') {
                @include grid--span((ratio: 1 / 2));
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
            }
        }

        .card-person--name {
            @include type--label-small;
            display: block;
        }

        .card-person--job-title {
            @include type--paragraph-small-reduced-lh;
            display: block;
        }
    }

    // Facets

    // States
}
