.contact-expert-card {
    // Vars
    $image-width-xxl: 218px;
    $image-height-xxl: 163px;
    $image-width-xl: 172px;
    $image-height-xl: 129px;
    $image-width-lg: 132px;
    $image-height-lg: 99px;
    $image-width-md: 168px;
    $image-height-md: 126px;

    // Support

    // Module
    & {
        display: flex;
        background-color: $c--monochrome-silver;
        color: $c--brand-blue-dark;

        &:hover {
            .contact-expert-card--image {
                transform: scale(1.1);
            }
        }

        @include mq('<=sm', 'screen') {
            display: block;
            height: auto;
        }

        @include mq('md', 'screen') {
            height: $image-height-md;
        }

        @include mq('lg', 'screen') {
            height: $image-height-lg;
        }

        @include mq('xl', 'screen') {
            height: $image-height-xl;
        }

        @include mq('xxl', 'screen') {
            height: $image-height-xxl;
        }

        .contact-expert-card--image-wrapper {
            @include mq('screen') {
                overflow: hidden;
            }

            @include mq('<=sm', 'screen') {
                height: auto;
                max-width: 100%;
                min-width: 100%;
            }

            @include mq('md', 'screen') {
                width: $image-width-md;
                height: $image-height-md;
            }

            @include mq('lg', 'screen') {
                width: $image-width-lg;
                height: $image-height-lg;
            }

            @include mq('xl', 'screen') {
                width: $image-width-xl;
                height: $image-height-xl;
            }

            @include mq('xxl', 'screen') {
                width: $image-width-xxl;
                height: $image-height-xxl;
            }
        }

        .contact-expert-card--image {
            flex: 1 1 auto;
            transform: scale(1);
            will-change: transform;
            transition: transform $trs--base;

            @include mq('<=sm', 'screen') {
                height: auto;
                max-width: 100%;
                min-width: 100%;
            }

            @include mq('md', 'screen') {
                width: $image-width-md;
                height: $image-height-md;
            }

            @include mq('lg', 'screen') {
                width: $image-width-lg;
                height: $image-height-lg;
            }

            @include mq('xl', 'screen') {
                width: $image-width-xl;
                height: $image-height-xl;
            }

            @include mq('xxl', 'screen') {
                width: $image-width-xxl;
                height: $image-height-xxl;
            }
        }

        .contact-expert-card--info {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 16px;

            @include mq('>lg') {
                padding: 24px;
            }
        }

        .contact-expert-card--name {
            @include type--label-small;
            margin-bottom: 1px;
        }

        .contact-expert-card--description {
            @include type--paragraph-small;
        }

        .contact-expert-card--text {
            display: block;
            margin-bottom: 10px;
        }
    }

    // Facets
    // States
}
