.mobile-nav-overlay {
    // Vars
    $sz--actions-padding: 32px;
    $sz--actions-btn: 52px;

    // Support

    // Module
    & {
        width: 100%;
        height: 100%;
        background-color: $c--monochrome-white;

        .mobile-nav-overlay--secondary-nav {
            padding-bottom: $sz--actions-btn + (2 * $sz--actions-padding);
        }

        .mobile-nav-overlay--col {
            @include grid--span((ratio: 4 / 4));
        }

        .mobile-nav-overlay--primary-nav {
            //background-color: $c--monochrome-medium-gray;
        }

        .mobile-nav-overlay--nav-list {
            //padding: 8px 0;
        }

        .mobile-nav-overlay--buttons-col {
            @include grid--span((ratio: 4 / 4));
        }

        .mobile-nav-overlay--buttons {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            max-width: 576px;
            margin: 0 auto;
            padding: 0 24px;
        }

        .mobile-nav-overlay--actions-container {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: $sz--actions-padding 0;
            width: 100%;
        }

        .mobile-nav-overlay--contact-btn {
            @include ff--base-medium;
            padding: 15px 40px 14px;
            font-size: 1.5rem;
            line-height: 1.33;

            @include mq('<=sm') {
                width: auto;
            }
        }

        .mobile-nav-overlay--search-btn {
            background-color: $c--monochrome-white;
            color: $c--brand-blue-dark;

            @include mq('<=sm') {
                width: auto;
            }
        }
    }

    // Facets

    // States
}
