.card-colored-list {
    // Vars

    // Support

    // Module
    & {
        .card-colored-list--content-col {
            @include grid--span((ratio: 10 / 12, mq: '>md'));
            @include grid--push((ratio: 1 / 12, mq: '>md'));
            @include grid--span((ratio: 12 / 12));
            @include grid--span((ratio: 12 / 12, mq: 'print'));

            @include mq('print') {
                margin-left: 0;
            }
        }

        .card-colored-list--list-row {
            justify-content: space-between;
        }

        .card-colored-list--item {
            @include mq('>xl', 'screen') {
                flex: 0 0 580px;
            }

            @include mq('xl', 'screen') {
                flex: 0 0 475px;
            }

            @include mq('lg', 'screen') {
                flex: 0 0 363px;
            }

            @include grid--span((ratio: 6 / 12, mq: 'md'));

            margin-bottom: $sz--inner-spacing-desktop;

            @include mq('<=md') {
                margin-bottom: $sz--inner-spacing-mobile;
            }

            @include mq('print') {
                margin-left: 0;
            }

            // Select everything except the last two elements
            &:nth-last-child(-n+2) {
                margin-bottom: 0;
            }

            // Add margin to top on every second item
            &:nth-child(2n) {
                margin: $sz--inner-spacing-desktop 0;
            }

            // Remove margin on the last item
            &:last-child {
                margin-bottom: 0;
            }

            @include mq('<=sm') {
                &.card-colored-list--item {
                    margin: 20px 0;

                    &:first-child {
                        margin-top: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    // Facets

    // States
}
