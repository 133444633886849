.navigation-sub-item {
    // Vars

    // Support

    // Module
    & {
        @include mq('<=md') {
            display: none;
        }

        position: relative;
        width: 100%;
        background-color: $c--monochrome-silver;
        transition: color $trs--base;

        .navigation-sub-item--outer-wrapper {
            display: block;
        }

        .navigation-sub-item--upper-wrapper {
            position: relative;
            display: block;
            padding: 24px;
            background-color: $c--monochrome-silver;

            @include mq('lg') {
                padding: 16px;
            }
        }

        .navigation-sub-item--lower-wrapper {
            opacity: 0;
            position: absolute;
            left: 0;
            display: block;
            padding: 0 24px;
            width: 100%;
            background-color: $c--monochrome-silver;
            transition-delay: opacity $trs--base;

            @include mq('lg') {
                padding: 0 16px;
            }

            &.navigation-sub-item--lower-wrapper__focus {
                padding-bottom: 24px;
                margin-top: -12px;
                opacity: 1;
            }
        }

        .navigation-sub-item--label {
            @include type--paragraph-small;
            display: block;
            color: $c--label-text;
        }

        .navigation-sub-item--title {
            @include type--label;

            @include mq('lg') {
                @include type--label-small;
            }
        }

        .navigation-sub-item--description {
            @include type--paragraph-small;
            color: $c--label-text;
        }

        .navigation-sub-item--arrow {
            display: block;
        }
    }

    // Facets

    // States
    & {
        // Focus
        & {
            @include has-focus(true) {
                color: $c--accent-blue;
            }
        }

        // Selected
        &.navigation-sub-item__selected {
            background-color: $c--accent-blue;

            @include has-focus(default) {
                color: $c--monochrome-white;
            }

            .navigation-sub-item--upper-wrapper {
                background-color: $c--accent-blue;
            }

            .navigation-sub-item--lower-wrapper {
                background-color: $c--accent-blue;
            }

            .navigation-sub-item--label {
                color: $c--monochrome-white;
            }

            .navigation-sub-item--description {
                color: $c--monochrome-white;
            }
        }
    }
}
