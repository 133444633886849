.list-numbers-entry {
    // Vars

    // Support

    // Module
    & {
        display: block;
        text-align: center;
        overflow: hidden;

        .list-numbers-entry--value {
            @include type--list-number-number;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 68px;
            color: $c--accent-blue;
            margin-bottom: 15px;
        }

        .list-numbers-entry--title {
            @include type--label;
            margin-bottom: 8px;

            @include mq('<=lg') {
                margin-bottom: 4px;
            }
        }

        .list-numbers-entry--text {
            @include type--paragraph-small;
        }
    }

    // Facets

    & {
        @each $color-key, $color-value in $accents {
            &.list-numbers-entry__foreground-accent-#{$color-key} {
                .list-numbers-entry--value {
                    color: $color-value;
                }
            }
        }

        &.list-numbers-entry__foreground-monochrome-silver {
            .list-numbers-entry--value {
                color: $c--monochrome-silver;
            }
        }
    }

    // States
}
