.testimonials {
    // Vars

    // Support

    // Module
    & {
        .testimonials--items {
            justify-content: center;
        }

        .testimonials--item {
            @include grid--span((ratio: 10 / 12));
            text-align: center;

            @include mq('>md') {
                &:nth-child(-n+2) {
                    margin-top: 0;
                }
            }

            &:only-child {
                @include grid--span((ratio: (8 / 12), mq: 'lg'));
                @include grid--span((ratio: (6 / 12), mq: '>lg'));
            }
        }

        .testimonials--item-image {
            @include grid--span((ratio: 9.5 / 12));
            @include grid--span((ratio: 8.5 / 12, mq: '<=xl'));
        }

        .testimonials--col {
            @include grid--span((ratio: 8 / 12, mq: '>xl'));
            @include grid--span((ratio: 8 / 12, mq: '<=xl'));
            @include grid--span((ratio: 12 / 12, mq: '<=md'));
            @include grid--span((ratio: 4 / 4, mq: '<=sm'));
        }

        .testimonials--image-row {
            justify-content: flex-end;
            padding-right: 32px;

            @include mq('<=md') {
                justify-content: center;
                padding-right: 0;
                margin-bottom: 24px;
            }
        }

        .testimonials--image-col {
            @include grid--span((ratio: 3 / 12, mq: '>xl'));
            @include grid--span((ratio: 4 / 12, mq: '<=xl'));
            @include grid--span((ratio: 2 / 4, mq: '<=sm'));

            //margin-right: 60px;
        }

        .testimonials--image {
            display: block;
            width: 100%;

            @include mq('<=md') {
                margin-bottom: 24px;
            }
        }

        .testimonials--blockquote {
            @include type--lead;
            display: block;
            margin-bottom: 16px;
            text-align: start;

            @include mq('<=md') {
                text-align: center;
            }
        }

        .testimonials--author {
            @include type--label-small;
            display: block;
            text-align: start;

            @include mq('<=md') {
                text-align: center;
            }
        }

        .testimonials--infos {
            @include type--paragraph-small;
            display: block;
            margin-top: 4px;
            text-align: start;

            @include mq('<=md') {
                margin-top: 2px;
                text-align: center;
            }
        }

        .testimonials--color-bar-col {
            @include section-color-bar($selector: 'testimonials--color-bar-col');
            @include grid--span((ratio: 12 / 12));

            display: flex;
            flex-direction: row;
            border-left: 4px solid;
            padding-left: 32px;

            @include mq('<=md') {
                border-left: none;
                padding-left: 0;
            }
        }

        .testimonials--color-bar-row {
            width: 100%;
        }

        .testimonials--figure {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            @include mq('<=md') {
                flex-direction: column;
            }
        }
    }

    // Facets

    & {
        @include section-background($selector: 'testimonials', $add-section-padding: true);
    }

    // States
}
