.media-overlay {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        width: 100%;

        .media-overlay--content {
            position: relative;
        }

        .media-overlay--close {
            position: absolute;
            bottom: 100%;
            left: 100%;
            margin-bottom: 16px;
            color: $c--monochrome-white;

            @include mq('<=md') {
                left: auto;
                right: -12px; // NOTE: Visually aligning the edge of the media file with the icon...
            }
        }

        .media-overlay--close-btn {
            cursor: pointer;
            display: block;
        }

        .media-overlay--row {
            justify-content: center;
        }

        .media-overlay--col {
            @include grid--span((ratio: 8 / 12, mq: '>lg'));
            @include grid--span((ratio: 10 / 12, mq: ('>sm' '<=lg')));
            @include grid--span((ratio: 1 / 1, mq: '<=sm'));
        }

        .media-overlay--video-wrapper {
            position: relative;
            width: 100%;
            padding-top: 9 / 16 * 100%;
        }

        .media-overlay--video {
            @include overlay;
        }
    }

    // Facets

    // States
}
