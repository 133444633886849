$sz--list-icon-width: 12px;
$sz--list-icon-gutter: 6px;
$sz--list-padding: ($sz--list-icon-width + $sz--list-icon-gutter);
$sz--list-vertical-spacing: 8px;

$sz--border-radius-base: 0;
$sz--border-width-base: 2px;

$sz--navigation-meta-height-desktop: 120px;
$sz--navigation-meta-height-mobile: 60px;

$sz--navigation-main-height-desktop-xl: 58px;
$sz--navigation-main-height-desktop-lg: 61px;

$sz--navigation-sub-height-desktop-xl: 0;
$sz--navigation-sub-height-desktop-lg: 0;
$sz--navigation-sub-height-mobile: 42px;

$sz--list-escalator-height-x-large: 820px;
$sz--list-escalator-height-large: 628px;
$sz--list-escalator-height-medium: 512px;

$sz--inner-spacing-desktop: 60px;
$sz--inner-spacing-mobile: 40px;
