.input-file {
    // Vars

    // Support

    // Module
    & {
        .input-file--row {
            display: flex;
            width: 100%;
            min-height: 36px;
        }

        .input-file--label {
            display: flex;
            cursor: pointer;

            @include mq('<=md') {
                flex-direction: column;
            }
        }

        .input-file--icon {
            display: flex;
            align-items: flex-end;
            cursor: pointer;

            @include mq('<=md') {
                justify-content: flex-end;
            }
        }
    }

    // Facets

    // States
}
