////
/// Header styles (hX)
////

@mixin type--hero {
    @include ff--base-bold;
    font-size: 5.1rem;
    line-height: 1.29;
    font-feature-settings: 'tnum' on, 'lnum' on;

    @include mq('<=lg') {
        font-size: 2.7rem;
        line-height: 1.37;
    }
}

@mixin type--h1 {
    @include ff--base-bold;
    font-size: 4.2rem;
    line-height: 1.38;
    font-feature-settings: 'tnum' on, 'lnum' on;

    @include mq('<=lg') {
        font-size: 2.7rem;
        line-height: 1.37;
    }
}

@mixin type--h2 {
    @include ff--base-bold;
    font-size: 2.9rem;
    line-height: 1.45;
    font-feature-settings: 'tnum' on, 'lnum' on;

    @include mq('<=lg') {
        font-size: 2.1rem;
        line-height: 1.43;
    }
}

@mixin type--h3 {
    @include ff--base-bold;
    font-size: 2.0rem;
    line-height: 1.3;
    font-feature-settings: 'tnum' on, 'lnum' on;

    @include mq('<=lg') {
        font-size: 1.7rem;
        line-height: 1.29;
    }
}

@mixin type--h4 {
    @include ff--base-bold;
    font-size: 1.7rem;
    line-height: 1.29;
    font-feature-settings: 'tnum' on, 'lnum' on;

    @include mq('<=lg') {
        font-size: 1.5rem;
        line-height: 1.6;
    }
}

@mixin type--h5 {
    @include ff--base-bold;
    font-size: 1.7rem;
    line-height: 1.59;
    font-feature-settings: 'tnum' on, 'lnum' on;
}

@mixin type--h6 {
    @include ff--base-bold;
    font-size: 1.7rem;
    line-height: 1.59;
    font-feature-settings: 'tnum' on, 'lnum' on;
}
