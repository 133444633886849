.skel {
    // Vars

    // Support

    // Module
    & {
        @include mq('screen') {
            position: relative;
            display: flex;
            flex-flow: column nowrap;
            width: 100%;
            min-height: 100%;
            overflow: hidden;
        }

        //.skel--header {}

        .skel--main {
            @include mq('screen') {
                display: flex;
                flex-flow: column nowrap;
                flex: 1 0 auto;
            }

            @include at-root(html, '.ie11') {
                min-height: 80vh;
            }
        }

        //.skel--footer {}
        //.skel--root {}
    }

    // Facets

    // States
}
