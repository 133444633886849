.list-team {
    // Vars

    // Support

    // Module
    & {
        .list-team--search-input-wrapper {
            position: relative;
        }

        .list-team--search-submit {
            position: absolute;
            top: 50%;
            right: 5px;
            transform: translateY(-50%);
            cursor: pointer;
        }

        .list-team--search-input {
            @include type--paragraph-small;
            padding: 16px 50px 16px 16px;
            height: 52px;
            line-height: 52px;
            border: 1px solid #9fa8be;
            width: 100%;
            background: $c--monochrome-white;
            color: $c--brand-blue-dark;
        }
    }
    // Facets

    // States
}
