.navigation-main {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'nav-items', cols: ('nav-item')));
        @include grid--space((mq: '>lg', gutter: 48px, row: 'nav-items', cols: ('nav-item')));
        @include grid--space((mq: '<=lg', gutter: 36px, row: 'nav-items', cols: ('nav-item')));
        background-color: $c--monochrome-white;

        @include mq('<=md') {
            display: none;
        }

        .navigation-main--link {
            @include type--label;
            display: block;
        }

        .navigation-main--nav-item {
            padding-bottom: 16px;

            &.navigation-main--nav-item__selected {
                .navigation-main--link {
                    color: $c--accent-blue;
                }
            }
        }

        .navigation-main--link {
            transition: color $trs--base;

            @include has-focus(true) {
                color: $c--accent-blue;
            }
        }

        .navigation-main--sub-line {
            @include type--paragraph-small;
            color: $c--label-text;
            display: block;
        }
    }

    // Facets

    // States
}
