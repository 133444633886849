.kununu-widget {
    // Vars
    $sz--border-radius: 8px;

    // Support

    // Module
    & {
        & {
            padding: 40px 0;
            background-color: $c--background-cyan;

            @include grid--root((row: 'list', cols: ('item')));
            @include grid--space((gutter: (40px 40px), row: 'list', cols: ('item')));
        }

        .kununu-widget--list {
            justify-content: center;
        }

        .kununu-widget--item {
            display: flex;
            align-items: center;
            justify-content: center;

            @include grid--col((ratio: 6 / 12, mq: 'md'));
            @include grid--col((ratio: 12 / 12, mq: '<=sm'));
        }

        .kununu-widget--link {
            position: relative;
            overflow: hidden;
            border-radius: $sz--border-radius;
        }

        .kununu-widget--image {
            display: block;
            max-width: 170px;
            max-height: 100px;
        }
    }

    // Facets

    // States
}
