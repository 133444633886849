.list-bullets-showcase {
    // Vars
    $item-spacing: 32px;

    // Support

    // Module
    & {
        @include section-background($selector: 'list-bullets-showcase', $add-section-padding: true);

        .list-bullets-showcase--layout-row {
            justify-content: center;
        }

        .list-bullets-showcase--layout-col {
            @include grid--span((ratio: 12 / 12));
            @include grid--span((ratio: 8 / 12, mq: '>sm'));
            @include grid--span((ratio: 10 / 12, mq: '>md'));
            @include grid--span((ratio: 12 / 12, mq: 'print'));
        }

        .list-bullets-showcase--item {
            @include grid--span((ratio: 12 / 12));
            @include grid--span((ratio: 6 / 12, mq: '>md'));
            @include grid--span((ratio: 12 / 12, mq: 'print'));

            & ~ .list-bullets-showcase--item {
                margin-top: $item-spacing;
            }

            @include mq('>md') {
                &:nth-child(-n+2) {
                    margin-top: 0;
                }
            }

            &:only-child {
                @include grid--span((ratio: 12 / 12));

                @include mq('>md') {
                    .list-bullets-showcase--title {
                        @include type--h2;
                        text-align: center;
                        margin-bottom: 32px;
                    }

                    .list-bullets-showcase--list {
                        columns: 100px 2;
                        column-gap: 24px;
                    }

                    .list-bullets-showcase--list-item {
                        margin-top: 0;
                        margin-bottom: 8px;
                    }
                }

                @include mq('>lg') {
                    .list-bullets-showcase--list {
                        column-gap: 32px;
                    }
                }
            }
        }

        .list-bullets-showcase--title {
            margin-bottom: 18px;
        }

        // TODO: move list styles to common place
        .list-bullets-showcase--list {
            @include type--ul;

            @include mq('screen') {
                padding-left: 0;
                list-style-type: none;

                .list-bullets-showcase--list-item {
                    position: relative;
                    padding-left: 52px;
                }
            }

            @include mq('screen') {
                .list-bullets-showcase--list-item::before {
                    content: '';
                    left: 16px;
                    top: 8px;
                    display: inline-block;
                    vertical-align: middle;
                    background: $c--accent-blue;
                    width: 11px;
                    height: 11px;
                }
            }
        }
    }

    // Facets

    // States
}
