.slider-feed {
    // Vars

    // Support

    // Module
    & {
        & {
            @include mq('<=sm') {
                background: $c--monochrome-silver;
            }
        }

        .slider-feed--slide {
            display: flex;
            height: 100%;
        }

        .slider-feed--row {
            justify-content: flex-end;
        }

        .slider-feed--col {
            width: 100%;

            @include mq('>md') {
                width: 11 / 12 * 100%;
            }
        }
    }

    // Facets
    & {
        @include section-background($selector: 'slider-feed', $add-section-padding: true);
    }


    // States
}
