.campaign-header {
    // Vars

    // Support

    // Module
    & {
        padding: 24px 0 16px;
        background-color: $c--monochrome-white;

        .campaign-header--home-link {
            display: block;
        }

        .campaign-header--logo {
            display: block;

            @include mq('<=md') {
                max-height: 40px;
            }
        }
    }

    // Facets

    // States
}
