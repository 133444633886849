.page-sharer {
    // Vars
    $mobile-top-offset: 84px;
    $default-top-offset: 28px;
    $big-top-offset: 238px;

    // Support

    // Module
    & {
        & {
            transform: translate(0, $default-top-offset);
            right: 36px;
            top: 0;

            // on mobile this component will stay fixed on the top of the page
            @include mq('<=md') {
                transform: translate(0, $mobile-top-offset);
                right: 24px;
            }
        }

        .page-sharer--link.page-sharer--link {
            cursor: pointer;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
        }

        .page-sharer--tooltip {
            position: absolute;
            top: 50%;
            right: 100%;

            @include type--paragraph-x-small;
            display: none;
            background-color: $c--brand-blue-dark;
            color: $c--monochrome-white;
            padding: 6px 8px;
            transform: translate(-12px, -50%);
            box-shadow: $shadow-level-1;
            white-space: nowrap;
            border-radius: 4px;
        }
    }

    // Facets
    & {
        &.page-sharer__with-offset {
            @include mq('>md') {
                top: $big-top-offset;

                &.page-sharer__is-sticky {
                    transform: translate(0, (($big-top-offset * -1) + $default-top-offset));
                }
            }
        }

        &.page-sharer__is-absolute {
            position: absolute;
        }

        &.page-sharer__is-sticky {
            position: fixed;
        }

        .page-sharer--link {
            &.page-sharer--link__facebook {
                color: $c--brand-facebook;
            }

            &.page-sharer--link__twitter {
                color: $c--brand-twitter;
            }

            &.page-sharer--link__link {
                color: $c--brand-link;
            }

            &.page-sharer--link__mail {
                color: $c--brand-mail;
            }

            @include has-focus(true) {
                color: $c--brand-blue-dark;
            }
        }
    }

    // States
    & {
        .page-sharer--link {
            @include has-focus(true) {
                .page-sharer--tooltip {
                    display: block;
                }
            }
        }
    }
}
