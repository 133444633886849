@mixin btn--base {
    border: $sz--border-width-base solid transparent;
    border-radius: 30px;
    display: inline-block;
    transition: transition-props($trs--base, color, border-color, background-color, opacity, transform);

    @include mq('<=sm') {
        width: 100%;
        text-align: center;
    }
}
