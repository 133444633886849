.tabs-view {
    // Vars

    // Support

    // Module
    & {
        & {
            display: none;
        }
    }

    // Facets
    &.tabs-view__active {
        & {
            display: block;
        }
    }

    // States
}
