.team-detail {
    // Vars
    // Support
    // Module
    & {
        .team-detail--image {
            @include mq('<=md') {
                margin-bottom: 40px;
            }
        }

        .team-detail--name {
            margin-bottom: 8px;
            color: $c--brand-blue-dark;

            @include mq('<=lg') {
                margin-bottom: 4px;
            }
        }

        .team-detail--role {
            @include type--lead;
            padding-bottom: 12px;

            @include mq('>xl') {
                padding-bottom: 16px;
            }
        }

        .team-detail--social-media-list {
            display: flex;
            padding: 12px 0;

            @include mq('>xl') {
                padding: 16px 0;
            }
        }

        .team-detail--social-media-icon {
            color: $c--brand-blue-dark;
        }

        .team-detail--social-media-link ~ .team-detail--social-media-link {
            margin-left: 8px;
        }

        .team-detail--table {
            width: 100%;
            margin-top: 12px;

            @include mq('>xl') {
                margin-top: 16px;
            }
        }

        .team-detail--table ~ .team-detail--table {
            margin-top: 40px;
        }

        .team-detail--table-row {
            border-top: 1px solid $c--monochrome-medium-gray;
            border-bottom: 1px solid $c--monochrome-medium-gray;
            width: 100%;
        }

        .team-detail--table-heading {
            @include type--label-small;
            text-align: left;
            width: 100px;
            padding: 12px 8px 12px 12px;
            box-sizing: content-box;

            @include mq('>lg') {
                width: 156px;
                padding: 12px 24px 12px 16px;
            }
        }

        .team-detail--table-field {
            @include type--paragraph-small;
            text-align: left;
            padding: 12px 16px 12px 0;
        }

        .team-detail--quote-col {
            padding: 12px 0;

            @include mq('>xl') {
                padding: 16px 0;
            }
        }

        .team-detail--quote {
            @include type--lead;
        }
    }

    // Facets
    // States
}
