.flyout {
    background-color: $c--monochrome-white;

    @include mq('<=md') {
        display: none;
    }

    .flyout__root {
        --flyout-label-padding: 16px;
        display: flex;
        flex-direction: row;
        list-style-type: none;

        @include grid--span((ratio: 12 / 12));
    }

    .flyout__main-label {
        @include type--label;
        color: $c--brand-blue-dark;
        display: flex;
        justify-items: center;
        align-items: center;
        cursor: pointer;
        padding-bottom: 16px;
    }

    .flyout__label-icon {
        margin-left: 8px;
    }

    .flyout__content {
        list-style-type: none;
        background-color: $c--monochrome-silver;
        border: solid 1px $c--monochrome-steel;
    }

    .flyout__content-item.flyout__content-item.flyout__content-item.flyout__content-item {
        margin-top: 0;

        .flyout__content-item-label {
            padding: 24px;
        }

        & + .flyout__content-item {
            border-top: solid 1px $c--monochrome-steel;
        }
    }

    .flyout__content-item-label.flyout__content-item-label.flyout__content-item-label {
        display: block;
        color: $c--brand-blue-dark;

        &:hover {
            color: $c--label-link;
        }

        &.flyout__content-item-label-active {
            color: $c--label-link;
        }
    }

    .flyout__content-item-subline {
        @include type--label;
        display: block;
    }

    .flyout__main-label.flyout__main-label.flyout__main-label {
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        align-items: flex-start;
        padding-right: 24px;
        padding-top: var(--flyout-label-padding);
        padding-bottom: var(--flyout-label-padding);
        color: $c--brand-blue-dark;

        .flyout__main-label-content {
            display: flex;
        }

        .flyout__main-label-subline {
            @include type--paragraph;
        }

        &.flyout__main-label-active {
            color: $c--label-link;
        }
    }

    .flyout__group {
        list-style: none;
        position: relative;


        & + .flyout__group .flyout__main-label {
            padding-left: 24px;
        }
    }
}
