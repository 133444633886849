.section-margin {
    // Vars

    // Support

    // Module
    & {
        margin-bottom: 80px;

        @include mq('<=md') {
            margin-bottom: 60px;
        }
    }

    // Facets
    &.section-margin__small {
        @include section-margin-small;
    }

    // States
}
