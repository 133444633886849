&.grid-list__story {
    .grid-list--tabs {
        @include grid--span((ratio: 12 / 12));

        margin-bottom: $sz--inner-spacing-desktop;

        @include mq('<=md') {
            margin-bottom: $sz--inner-spacing-mobile;
        }
    }

    .grid-list--item {
        @include grid--span((ratio: 1 / 1, mq: '<=sm'));
        overflow: hidden;

        @include mq('>sm') {
            margin-bottom: $sz--inner-spacing-desktop;

            &:nth-child(3n + 1), &:nth-child(3n + 2) {
                @include grid--span((ratio: 1 / 2));
            }

            &:nth-child(6n + 3) {
                @include grid--span((ratio: 4 / 5, mq: '>md'));
                @include grid--pull((ratio: 1 / 5, mq: '>md'));

                @include grid--span((ratio: 10 / 12, mq: '<=md'));
                @include grid--pull((ratio: 2 / 12, mq: '<=md'));
            }

            &:nth-child(6n + 6) {
                @include grid--span((ratio: 4 / 5, mq: '>md'));
                @include grid--push((ratio: 1 / 5, mq: '>md'));

                @include grid--span((ratio: 10 / 12, mq: '<=md'));
                @include grid--push((ratio: 2 / 12, mq: '<=md'));
            }
        }

        @include mq('<=sm') {
            margin-bottom: $sz--inner-spacing-desktop;
            margin-top: 0;
        }

        @include mq('md') {
            margin-bottom: $sz--inner-spacing-mobile;

            &:nth-child(3n + 2) {
                margin-top: $sz--inner-spacing-mobile;
            }
        }
    }
}
