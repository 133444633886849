.comp-list-link {
    // Vars

    // Support

    // Module
    & {
        @include section-background($selector: 'comp-list-link', $add-section-padding: true);

        .comp-list-link--header {
            margin-bottom: 48px;
        }

        .comp-list-link--title {
            margin-bottom: 16px;
            color: $c--brand-blue-dark;
            text-align: center;
        }

        .comp-list-link--text {
            @include type--paragraph;
            justify-content: center;
            text-align: center;
        }

        .comp-list-link--text-col {
            @include grid--span((ratio: 12 / 12, mq: '<=md'));
            @include grid--span((ratio: 10 / 12, mq: 'lg'));
            @include grid--span((ratio: 8 / 12, mq: '>lg'));
        }

        .comp-list-link--row {
            justify-content: center;
        }

        .comp-list-link--col {
            @include grid--span((ratio: 12 / 12, mq: '<=md'));
            @include grid--span((ratio: 10 / 12, mq: 'lg'));
            @include grid--span((ratio: 8 / 12, mq: '>lg'));
        }

        .comp-list-link--list {
            padding: 0 16px;
        }

        .comp-list-link--entry {
            @include grid--span((ratio: 1 / 2, mq: '>md'));

            & ~ .comp-list-link--entry {
                margin-top: 28px;
            }

            &:nth-child(-n+2) {
                @include mq('>md') {
                    margin-top: 0;
                }
            }

            @include mq('<=md') {
                width: 100%;
            }
        }

        .comp-list-link--actions {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            margin-top: 50px;
        }
    }

    // Facets
    & {
        &.comp-list-link__min {
            .comp-list-link--entry {
                & ~ .comp-list-link--entry {
                    margin-top: 22px;
                }

                &:nth-child(-n+2) {
                    @include mq('>md') {
                        margin-top: 0;
                    }
                }
            }
        }
    }
    // States
}
