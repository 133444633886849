.form-message {
    // Vars

    // Support

    // Module
    & {
        display: block;
        margin-top: 24px;
        padding: 16px;
    }

    // Facets
    & {
        &.form-message__error {
            border: 1px solid $c--signal-error;
            color: $c--signal-error;
        }

        &.form-message__success {
            border: 1px solid $c--brand-blue;
            color: $c--brand-blue;
        }
    }

    // States
}
