.breadcrumb-item {
    // Vars
    $breadcrumb-height: 24px;

    // Support

    // Module
    & {
        & {
            display: flex;
            align-items: center;
            min-height: $breadcrumb-height;
        }

        .breadcrumb-item--link {
            @include type--breadcrumb;
            font-weight: $fw--medium;
            color: $c--brand-blue-dark;
            transition: color $trs--base;
            display: inline-block;

            max-width: 250px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            @include has-focus(true) {
                color: $c--brand-blue-dark;
            }
        }

        .breadcrumb-item--icon {
            display: inline-block;
            margin: 0 4px;
            color: $c--brand-blue-dark;
        }

        .breadcrumb-item--dot {
            display: inline-block;
            width: 8px;
            height: 8px;
            background-color: $c--accent-blue;
            border-radius: 50%;
        }

        .breadcrumb-item--dot-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    // Facets

    // States
}
