.comp-leftright-list-link {
    // Vars

    // Support

    // Module
    & {
        .comp-leftright-list-link--description {
            margin-top: 32px;
        }

        .comp-leftright-list-link--list {
            margin-top: 24px;
        }

        .comp-leftright-list-link--content {
            @include mq('md') {
                margin-top: 40px;
            }

            @include mq('<=sm') {
                margin-top: 24px;
            }
        }

        .comp-leftright-list-link--action {
            display: flex;
            margin-top: 32px;
        }
    }

    // Facets

    // States
}
