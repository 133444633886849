&.page__base {
    // Vars
    $sz--page-offset-large: 80px;
    $sz--page-offset-medium: 20px;
    $sz--navigation-height-desktop-xl: $sz--navigation-meta-height-desktop + $sz--navigation-main-height-desktop-xl;
    $sz--navigation-height-desktop-lg: $sz--navigation-meta-height-desktop + $sz--navigation-main-height-desktop-lg;
    $sz--navigation-height-mobile: $sz--navigation-meta-height-mobile;

    // Support

    // Module
    & {
        @include mq('screen') {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            flex: 1 0 auto;
        }

        .page--main {
            @include mq('screen') {
                flex: 1 0 auto;
            }

            // Padding
            & {
                @include mq('>lg', 'screen') {
                    padding-top: $sz--navigation-height-desktop-xl + $sz--page-offset-large;
                }

                @include mq('lg', 'screen') {
                    padding-top: $sz--navigation-height-desktop-lg + $sz--page-offset-large;
                }

                @include mq('<=md', 'screen') {
                    padding-top: 120px;
                }
            }
        }
    }

    // Facets
    & {
        &.page__is-campaign {
            .page--main {
                padding-top: 0;
            }
        }

        &.page__has-back-button {
            .page--main {
                @include mq('>md', 'screen') {
                    padding-top: 120px;
                }

                @include mq('md', 'screen') {
                    padding-top: 120px;
                }

                @include mq('<=md', 'screen') {
                    padding-top: 120px;
                }
            }
        }
    }

    // States
}
