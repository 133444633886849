.list-team-entry {
    // Vars

    // Support

    // Module
    & {
        text-align: center;

        .list-team-entry--image {
            display: block;
            width: 100%;
        }

        .list-team-entry--content {
            margin-top: 16px;
        }

        .list-team-entry--title {
            @include type--label-small;
        }

        .list-team-entry--description {
            @include type--paragraph-small-reduced-lh;
            margin-top: 2px;
        }
    }

    // Facets

    // States
}
