.cookie-monster-banner {
    // Vars

    // Support

    // Module
    & {
        @include type--form-text;
        z-index: z-index('cookie-banner');
        position: fixed;
        bottom: 0;
        left: 0;
        display: none;
        opacity: 0;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: $c--brand-blue-dark;
        color: $c--monochrome-white;
        padding: 8px;

        @include mq('<=md') {
            flex-direction: column;
            padding: 16px 24px;
            text-align: center;
        }

        .cookie-monster-banner--content {
            margin-right: 24px;

            @include mq('<=md') {
                margin-right: 0;
                margin-bottom: 8px;
            }

            a {
                @include type--form-text;
            }
        }

        .cookie-monster-banner--btn {
            @include type--form-text;
            background: transparent;
            border: 1px solid rgba($c--monochrome-white, 0.4);
            border-radius: 37px;
            margin-top: 0;
            padding: 8px 16px;
            cursor: pointer;
        }
    }

    // Facets
    & {
        &.cookie-monster-banner__active {
            display: flex;
            opacity: 1;
        }
    }
    // States
}
