.list-search-results {
    // Vars

    // Support

    // Module
    & {
        @include section-padding;

        .list-search-results--layout-row {
            justify-content: center;
        }

        .list-search-results--layout-col {
            @include grid--span((ratio: 1 / 1));
            @include grid--span((ratio: 8 / 12, mq: 'lg'));
            @include grid--span((ratio: 1 / 2, mq: '>lg'));
        }

        .list-search-results--item ~ .list-search-results--item {
            margin-top: 36px;
        }

        .list-search-results--title {
            @include type--h3;
            margin-bottom: 10px;
            color: $c--brand-blue-dark;
        }

        .list-search-results--text {
            color: $c--brand-blue-dark;
        }

        .list-search-results--link {
            display: block;
            margin-top: 8px;
        }

        .list-search-results--hit {
            background: $c--accent-yellow;
            box-shadow: 0 0 0 2px $c--accent-yellow;
            color: $c--brand-blue-dark;
        }

        .list-search-results--item-inner {
            display: block;
        }
    }
    // Facets
    // States
}
