.card-colored-item {
    // Vars

    // Support

    // Module
    & {
        & {
            @include mq('screen') {
                padding-left: 125px;
            }

            @include mq('xl', 'screen') {
                padding-left: 102px;
            }

            @include mq('lg', 'screen') {
                padding-left: 78px;
            }

            @include mq('md', 'screen') {
                padding-left: 48px;
            }
        }

        .card-colored-item--visual-wrapper {
            @include mq('screen') {
                display: block;
                width: 100%;
                height: 238px;
                overflow: hidden;
            }


            @include mq('xl', 'screen') {
                height: 192px;
            }

            @include mq('lg', 'screen') {
                height: 146px;
            }

            @include mq('md', 'screen') {
                height: 122px;
            }

            @include mq('<=sm', 'screen') {
                height: 136px;
            }

            &.card-colored-item--visual-wrapper__has-link {
                &:hover {
                    .card-colored-item--visual {
                        transform: scale(1.1);
                    }
                }
            }
        }

        .card-colored-item--visual {
            display: block;
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
            transition: transform $trs--base;
        }

        .card-colored-item--caption {
            position: relative;
            padding-bottom: 24px;

            @include mq('<=lg') {
                padding-bottom: 16px;
            }
        }

        .card-colored-item--background-wrapper {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            overflow: hidden;
        }

        .card-colored-item--content-background {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }

        .card-colored-item--content-wrapper {
            position: relative;
        }
    }

    // Facets
    @each $color-key, $color-value in $backgrounds {
        &.card-colored-item__color-accent-#{$color-key} {
            .card-colored-item--content-background {
                background-color: $color-value;
            }

            .card-colored-item--content-wrapper {
                @include mq('screen') {
                    left: -101px;
                    width: calc(100% + 101px);
                }

                @include mq('xl', 'screen') {
                    left: -78px;
                    width: calc(100% + 78px);
                }

                @include mq('lg', 'screen') {
                    left: -62px;
                    width: calc(100% + 62px);
                }

                @include mq('md', 'screen') {
                    left: -32px;
                    width: calc(100% + 32px);
                }
            }

            .card-colored-item--caption {
                padding-top: 24px;

                @include mq('<=lg') {
                    padding-top: 16px;
                }
            }
        }
    }

    &.card-colored-item__reversed {
        & {
            display: flex;
            flex-direction: column-reverse;

            @include mq('<=sm') {
                flex-direction: column;
            }
        }
    }

    // States
}
