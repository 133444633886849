.scroll-reveal {
    // Vars

    // Support

    // Module
    & {
        & {
            opacity: 0;
            transform: translate(0, 150px);
            transition: transition-props($trs--base-fn 400ms, transform, opacity);
            will-change: transform;
        }
    }

    // Facets


    // States
    & {
        &.scroll-reveal__is-visible {
            opacity: 1;
            transform: translate(0, 0);
        }
    }
}
