.media {
    // Vars
    $sz--video-height: 9 / 16 * 100%;

    // Support

    // Module
    & {
        .media--row {
            justify-content: center;
        }

        .media--wrapper {
            position: relative;
            padding-top: $sz--video-height;
        }

        .media--frame {
            @include overlay;
        }

        .media--title {
            margin-bottom: 32px;
        }

        .media--description {
            margin-top: 16px;
        }

        .media--content-description {
            @include type--paragraph-small;
            display: block;
        }

        .media--source-description {
            @include type--paragraph-x-small;
            display: block;
            margin-top: 8px;
        }
    }

    // Facets
    & {
        // Image
        &.media__image {
            .media--wrapper {
                padding-top: 0;
            }

            .media--frame {
                position: relative;
                display: block;
                width: 100%;
                height: auto;
            }
        }

        &.media__default-width {
            @include section-background($selector: 'media', $add-section-padding: true);

            .media--col {
                @include grid--span((ratio: 6 / 12, mq: '>lg'));
                @include grid--span((ratio: 8 / 12, mq: ('>sm' '<=lg')));
                @include grid--span((ratio: 1 / 1, mq: '<=sm'));
            }
        }

        &.media__full-width {
            @include section-background($selector: 'media', $add-section-padding: true);

            .media--col {
                @include grid--span((ratio: 1 / 1));
            }
        }

        &.media__full-bleed {
            // NOTE: A title doesn't make sense in a full bleed media
            .media--title {
                @include hide-visually;
            }

            .media--container.media--container {
                padding: 0;
                max-width: none;
            }

            .media--col {
                @include grid--span((ratio: 1 / 1));
            }
        }
    }

    // States
}
