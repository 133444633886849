.card-partner {
    // Vars

    // Support

    // Module
    & {
        .card-partner--title-col {
            @include grid--span((ratio: 12 / 12));
            text-align: center;
        }

        .card-partner--title {
            @include type--h2;
            margin-bottom: 16px;
        }

        .card-partner--lead {
            @include type--paragraph;
        }

        .card-partner--main-col {
            @include grid--span((ratio: 8 / 12, mq: 'xxl'));
            @include grid--push((ratio: 2 / 12, mq: 'xxl'));

            @include grid--span((ratio: 10 / 12, mq: 'xl'));
            @include grid--push((ratio: 1 / 12, mq: 'xl'));

            @include grid--span((ratio: 12 / 12, mq: 'lg'));
            @include grid--push((ratio: 0, mq: 'lg'));

            @include grid--span((ratio: 10 / 12, mq: 'md'));
            @include grid--push((ratio: 1 / 12, mq: 'md'));

            @include grid--span((ratio: 12 / 12, mq: '<=sm'));
            @include grid--push((ratio: 0, mq: '<=sm'));
        }

        .card-partner--card {
            @include grid--span((ratio: 5 / 10));
            @include grid--span((ratio: 10 / 10, mq: '<=md'));

            margin-top: 32px;

            &.card-partner--card__has-link {
                &:hover {
                    .card-partner--card-title, .card-partner--card-description {
                        color: $c--accent-blue;
                    }
                }
            }

            &.card-partner--card__standalone {
                @include grid--span((ratio: 6 / 10));
                @include grid--push((ratio: 2 / 10));
                @include grid--span((ratio: 10 / 10, mq: '<=md'));
                @include grid--push((ratio: 0, mq: '<=md'));

                .card-partner--visual-outer {
                    @include grid--span((ratio: 2 / 6));
                }
            }
        }

        .card-partner--outer {
            display: flex;
            background-color: $c--monochrome-silver;
            height: 129px;

            @include mq('<=sm') {
                display: block;
                height: auto;
                padding: 0;
            }
        }

        .card-partner--visual-outer {
            display: flex;
            align-items: center;
            justify-content: center;

            @include grid--span((ratio: 2 / 5));
            @include grid--span((ratio: 4 / 12, mq: 'md'));


            @include mq('>md') {
                padding: 0 48px 0 24px;
            }

            @include mq('md') {
                padding: 0 32px 0 16px;
            }

            @include mq('<=sm') {
                padding: 0;
                height: 112px;
                margin-right: 0;
            }
        }

        .card-partner--visual {
            display: block;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 129px;

            @include mq('<=sm') {
                max-height: 112px;
            }
        }

        .card-partner--details {
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include mq('<=sm') {
                padding: 16px 0;
                text-align: center;
            }
        }

        .card-partner--card-title {
            display: block;

            @include type--label-small;
            transition: color $trs--base;
        }

        .card-partner--card-description {
            display: block;

            @include type--paragraph-small;

            transition: color $trs--base;
        }
    }

    // Facets

    // States
}
