.page {
    // Vars

    // Support

    // Module

    // Facets
    @import 'page__base';
    @import 'page__no-offset';

    // States
}
