.spinning-loader {
    // Vars

    // Support

    // Module
    & {
        display: inline-block;
        margin: auto;

        .spinning-loader--svg {
            width: 100%;
            height: 100%;

            .spinning-loader--svg-path {
                fill: none;
                stroke: currentColor;
                stroke-width: 2px;
            }
        }
    }

    // Facets
    & {
        // Visibility
        & {
            & {
                @include is-visible(false, $trs--base-speed);
            }

            &.spinning-loader__is-active {
                @include is-visible(true, $trs--base-speed);
            }
        }
    }

    // States
}
