.input-file-message {
    // Vars

    // Support

    // Module
    & {
        @include type--form-text;

        display: block;
        font-size: 1.2rem;
        line-height: 1.3;
        color: $c--signal-error;
        padding-left: 188px;
        margin-top: 3px;

        @include mq('<=md') {
            padding-left: 0;
        }
    }

    // Facets

    // States
}
