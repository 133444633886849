/// Primary
///
@mixin btn--shadowed {
    // Vars

    // Support

    // Module

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--shadowed-base;
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--shadowed-focus;
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': default, 'active': true)) {
            @include btn--shadowed-active;
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default)) {
            @include btn--shadowed-disabled;
        }
    }
}

/// Base
///
@mixin btn--shadowed-base {
    color: $c--brand-blue-dark;
    border-color: transparent;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
    background-color: $c--monochrome-white;

    @include mq('<=sm') {
        width: auto;
    }
}

/// Focus
///
@mixin btn--shadowed-focus {
    color: $c--accent-blue;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
}

/// Active
///
@mixin btn--shadowed-active {
    //
}

/// Disabled
///
@mixin btn--shadowed-disabled {
    color: $c--monochrome-silver;
    background-color: $c--monochrome-light-gray;
}
