.footer {
    // Vars
    $footer-nav-spacing: 32px;

    // Support

    // Module
    & {
        @include mq('>lg', 'screen') {
            padding-bottom: 80px;
        }

        @include mq('<=lg', 'screen') {
            padding-bottom: 60px;
        }

        .footer--container.footer--container,
        .footer--row.footer--row {
            @include mq('print') {
                display: block;
            }
        }

        .footer--row ~ .footer--row {
            margin-top: 40px;
        }

        .footer--row__vcenter {
            align-items: center;

            @include mq('<=sm', 'screen') {
                align-items: flex-start;
            }
        }

        .footer--text,
        .footer--link {
            @include type--paragraph-x-small;
        }

        .footer--title {
            @include type--label-small;
            margin-bottom: 15px;
        }

        .footer--text {
            display: block;
        }

        .footer--nav {
            @include grid--span((ratio: 1 / 4));
            @include grid--span((ratio: 1, mq: 'print'));
            @include grid--span((ratio: 1 / 2, mq: 'md'));
            @include grid--span((ratio: 1 / 1, mq: '<=sm'));


            @include mq('md') {
                margin-top: $footer-nav-spacing;

                &:nth-child(-n+2) {
                    margin-top: 0;
                }
            }

            @include mq('<=sm') {
                & ~ .footer--nav {
                    margin-top: $footer-nav-spacing;
                }
            }

            &.footer--nav__grow {
                flex-grow: 1;
            }

            &.footer--nav__align-right {
                margin-left: auto;
            }
        }
    }

    // Facets

    // States
}
