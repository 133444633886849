.footer-social-media {
    // Vars

    // Support

    // Module
    & {
        @include grid--span((ratio: 1 / 2));

        @include mq('<=sm') {
            @include grid--span((ratio: 1 / 1));
        }

        .footer-social-media--list {
            display: flex;
        }

        .footer-social-media--list-item ~ .footer-social-media--list-item {
            margin-left: 7px;
        }

        .footer-social-media--link {
            display: block;
            color: $c--brand-blue-dark;
            transition: transition-props($trs--base, color);

            @include has-focus(true) {
                color: $c--accent-blue;
            }
        }
    }

    // Facets

    // States
}
