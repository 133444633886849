.grid-list {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'items', cols: ('item')));
        @include grid--space((gutter: (0 94px), row: 'items', cols: ('item'), mq: '>md'));
        @include grid--space((gutter: (0 24px), row: 'items', cols: ('item'), mq: 'md'));
        @include grid--space((gutter: (0 16px), row: 'items', cols: ('item'), mq: '<=sm'));

        .grid-list--col {
            @include grid--span((ratio: 10 / 12, mq: '>md'));
            @include grid--push((ratio: 1 / 12, mq: '>md'));

            @include grid--span((ratio: 12 / 12, mq: '<=md'));
        }

        .grid-list--tabs,
        .grid-list--search {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        .grid-list--search {
            margin-bottom: $sz--inner-spacing-desktop;

            @include mq('<=md') {
                margin-bottom: $sz--inner-spacing-mobile;
            }
        }

        .grid-list--header {
            @include grid--span((ratio: 1 / 1));
        }

        .grid-list--search-form {
            @include grid--span((ratio: 1 / 1));
        }

        .grid-list--actions {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
        }
    }

    // Facets
    @import 'grid-list__story';
    @import 'grid-list__story-odd-offset';
    @import 'grid-list__team';
    @import 'grid-list__story-teaser';

    // States
}
