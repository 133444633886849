.related-blog-entry {
    // Vars

    // Support

    // Module
    & {
        display: block;
        overflow: hidden;
        background-color: rgba($c--monochrome-white, 0);
        transition: background-color $trs--base;


        .related-blog-entry--link {
            display: block;
            padding: 24px 24px 0;
            transition: background-color $trs--base;


            @include mq('<=lg') {
                padding: 16px;
            }

            @include has-focus(true) {
                background-color: rgba($c--monochrome-white, 1);

                ~ .related-blog-entry--tags {
                    background-color: rgba($c--monochrome-white, 1);
                }
            }
        }

        .related-blog-entry--meta {
            @include type--paragraph-small;
            display: block;
            margin-bottom: 8px;

            @include mq('<=lg') {
                margin-bottom: 4px;
            }
        }

        .related-blog-entry--title {
            @include type--label;
            margin-bottom: 8px;

            @include mq('<=lg') {
                margin-bottom: 4px;
            }
        }

        .related-blog-entry--text {
            @include type--paragraph-small;
        }

        .related-blog-entry--tag-container {
            @include grid--root;
        }

        .related-blog-entry--tags {
            display: block;
            padding: 20px 24px 24px;
            transition: background-color $trs--base;

            @include mq('<=lg') {
                padding: 12px 16px 16px;
            }
        }
    }

    // Facets

    // States
}
