.related-blog {
    // Vars

    // Support

    // Module
    & {
        @include section-padding;
        background-color: $c--monochrome-silver;

        .related-blog--title {
            margin-bottom: 32px;
            color: $c--brand-blue-dark;
            text-align: center;
        }

        .related-blog--row.related-blog--row.related-blog--row {
            flex-flow: row nowrap;
        }

        .related-blog--entry {
            @include grid--span((ratio: 1 / 3, mq: '>md'));
            @include grid--span((ratio: 2 / 5, mq: 'md'));
            @include grid--span((ratio: 3 / 4, mq: '<=sm'));
            overflow: hidden;
        }

        .related-blog--actions {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            margin-top: 32px;

            @include mq('<=lg') {
                margin-top: 24px;
            }
        }
    }

    &.related-blog__cols1 {
        // stylelint-disable-next-line plugin/selector-bem-pattern
        .related-blog--row {
            justify-content: center;
        }

        .related-blog--entry {
            @include grid--span((ratio: 4 / 12, mq: '>md'));
            @include grid--span((ratio: 6 / 12, mq: ('md')));
        }
    }

    &.related-blog__cols2 {
        .related-blog--row {
            @include mq('>sm') {
                justify-content: center;
            }
        }
    }

    &.related-blog__cols4 {
        .related-blog--entry {
            @include grid--span((ratio: 1 / 4, mq: '>md'));
        }
    }

    // States
}
