.anchor-placeholder {
    // Vars
    $sz--navigation-height-desktop-xl: $sz--navigation-meta-height-desktop + $sz--navigation-main-height-desktop-xl + 48px;
    $sz--navigation-height-desktop-lg: $sz--navigation-meta-height-desktop + $sz--navigation-main-height-desktop-lg + 48px;
    $sz--navigation-height-desktop-md: $sz--navigation-sub-height-mobile + 32px;

    // Support

    // Module
    & {
        & {
            position: relative;
        }

        .anchor-placeholder--trigger {
            position: absolute;
            top: 0;
            display: block;

            @include at-root('body.sticky-header') {
                @include mq('>lg') {
                    top: $sz--navigation-height-desktop-xl * -1;
                }

                @include mq('lg') {
                    top: $sz--navigation-height-desktop-lg * -1;
                }

                @include mq('<=md') {
                    top: $sz--navigation-height-desktop-md * -1;
                }
            }
        }
    }

    // Facets
    & {
        &.anchor-placeholder__is-subnav-visible {
            .anchor-placeholder--trigger {
                @include mq('>lg') {
                    top: $sz--navigation-sub-height-desktop-xl * -1;
                }

                @include mq('lg') {
                    top: $sz--navigation-sub-height-desktop-lg * -1;
                }

                @include mq('<=md') {
                    top: $sz--navigation-sub-height-mobile * -1;
                }

                @include at-root('body.sticky-header') {
                    @include mq('>lg') {
                        top: ($sz--navigation-height-desktop-xl + $sz--navigation-sub-height-desktop-xl) * -1;
                    }

                    @include mq('lg') {
                        top: ($sz--navigation-height-desktop-lg + $sz--navigation-sub-height-desktop-lg) * -1;
                    }

                    @include mq('<=md') {
                        top: ($sz--navigation-height-desktop-md + $sz--navigation-sub-height-mobile) * -1;
                    }
                }
            }
        }
    }

    // States
}
