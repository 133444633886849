// stylelint-disable plugin/selector-bem-pattern

$vs-colors: (
    lightest: $c--monochrome-steel,
    light: $c--monochrome-dark-gray,
    dark: $c--monochrome-dark-gray,
    darkest: $c--monochrome-transparent-gray,
);

//  Global Component Variables
$vs-component-line-height: 1;
$vs-component-placeholder-color: $c--monochrome-steel;

//  Active State
$vs-state-active-bg: $c--accent-blue;
$vs-state-active-color: $c--monochrome-white;

//  Disabled State
$vs-state-disabled-bg: $c--monochrome-silver;
$vs-state-disabled-color: map_get($vs-colors, 'light');
$vs-state-disabled-controls-color: map_get($vs-colors, 'light');
$vs-state-disabled-cursor: not-allowed;

//  Borders
$vs-border-width: 1px;
$vs-border-style: solid;
$vs-border-radius: 0;
$vs-border-color: $c--monochrome-steel;

//  Component Controls: Clear, Open Indicator
$vs-controls-color: map_get($vs-colors, 'light');
$vs-controls-size: 1;
$vs-controls-deselect-text-shadow: 0 1px 0 $c--monochrome-white;

//  Selected
$vs-selected-bg: #f0f0f0;
$vs-selected-border-color: $vs-border-color;
$vs-selected-border-style: $vs-border-style;
$vs-selected-border-width: $vs-border-width;

//  Dropdown
$vs-dropdown-z-index: z-index('autocomplete-search-dropdown');
$vs-dropdown-min-width: 160px;
$vs-dropdown-max-height: 182px;
$vs-dropdown-box-shadow: 0 3px 6px 0 map_get($vs-colors, 'darkest');
$vs-dropdown-bg: $c--monochrome-white;

@import 'node_modules/vue-select/src/scss/vue-select';

.autocomplete-search {
    position: relative;

    .autocomplete-search--search-icon {
        position: absolute;
        right: 6px;
        top: 6px;
    }
}
