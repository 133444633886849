@mixin section-background($selector, $add-section-padding: false) {
    @each $color-key, $color-value in $backgrounds {
        &.#{$selector}__accent-#{$color-key} {
            background-color: $color-value;

            @if ($add-section-padding) {
                @include section-padding;
            }
        }

        &.#{$selector}__accent-#{$color-key} + .#{$selector}__accent-#{$color-key} {
            background-color: $color-value;

            @if ($add-section-padding) {
                padding-top: 0;
            }
        }
    }

    &.#{$selector}__monochrome-silver {
        background-color: $c--monochrome-silver;

        @if ($add-section-padding) {
            @include section-padding;
        }
    }
}
