// stylelint-disable plugin/selector-bem-pattern

.vs__search {
    @include type--paragraph-small;
    padding: 16px 50px 16px 16px;
    height: 50px;
    width: 100%;
    margin-top: 0;
    background: $c--monochrome-white;
    color: $c--brand-blue-dark;

    &:focus {
        @include type--paragraph-small;
        padding: 16px 50px 16px 16px;
        margin-top: 0;
    }
}

.vs__dropdown-option {
    @include type--paragraph-small;
    display: block;
    height: 52px;
    padding: 16px 50px 16px 16px;

    &:hover {
        background: $c--monochrome-silver;
        color: $c--brand-blue-dark;
    }
}

.vs__dropdown-menu {
    padding: 0;
}

.vs__dropdown-toggle {
    padding: 0;
}

.vs__selected {
    @include type--paragraph-small;
    position: absolute;
    left: 16px;
    top: 16px;
    padding: 0;
    margin: 0;
}

.vs__selected-options {
    padding: 0;
}

.vs__actions {
    display: none;
}
