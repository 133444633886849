.slider-stories {
    // Vars

    // Support

    // Module
    & {
        .slider-stories--title {
            text-align: center;
            margin-bottom: 32px;

            @include mq('<=md') {
                margin-bottom: 24px;
            }
        }

        .slider-stories--actions {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            margin-top: 32px;


            @include mq('<=lg') {
                margin-top: 24px;
            }

            @include mq('<=sm') {
                margin: 24px 16px 0;
            }
        }

        .slider-stories--slide {
            display: flex;
            height: 100%;
        }
    }

    // Facets
    & {
        @include section-background($selector: 'slider-stories', $add-section-padding: true);
    }


    // States
}
