.form-gated {
    // Vars

    // Support

    // Module
    & {
        @include section-background($selector: 'form-gated', $add-section-padding: true);

        .form-gated--col {
            @include grid--span((ratio: 6 / 12, mq: '>lg'));
            @include grid--push((ratio: 3 / 12, mq: '>lg'));

            @include grid--span((ratio: 6 / 12, mq: ('>sm' '<=lg')));
            @include grid--push((ratio: 2 / 12, mq: ('>sm' '<=lg')));

            @include grid--span((ratio: 12 / 12, mq: ('<=sm')));
        }

        .form-gated--field-col {
            @include grid--span((ratio: 1 / 1));
        }

        .form-gated--header {
            margin-bottom: 24px;
        }

        .form-gated--description {
            margin-top: 16px;
        }

        .form-gated--actions {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            margin-top: 24px;
        }
    }

    // Facets

    // States
}
