.facts-image-image {
    // Vars
    // Support
    // Module
    & {
        display: flex;
        align-items: center;

        .facts-image-image--image {
            width: 172px;
            height: 172px;
            margin-right: 24px;
            object-fit: contain;

            @include mq('<=sm') {
                margin-right: 16px;
            }

            @include at-root(html, '.ie11') {
                height: auto;
                max-height: 172px;
                width: auto;
                max-width: 172px;
            }
        }

        .facts-image-image--number {
            @include type--facts-image-number;

            display: flex;
            color: $c--accent-blue;
            margin-bottom: 8px;
            text-align: right;
        }

        .facts-image-image--content {
            @include type--label;
        }

        .facts-image-image--text {
            @include type--paragraph;
        }
    }

    // Facets
    & {
        @each $color-key, $color-value in $accents {
            &.facts-image-image__accent-#{$color-key} {
                .facts-image-image--number {
                    color: $color-value;
                }
            }
        }

        &.facts-image-image__monochrome-silver {
            .facts-image-image--number {
                color: $c--monochrome-silver;
            }
        }
    }
    // States
}
