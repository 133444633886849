.list-numbers {
    // Vars

    // Support

    // Module
    & {
        .list-numbers--title {
            margin-bottom: 16px;
            color: $c--brand-blue-dark;
            text-align: center;
        }

        .list-numbers--text {
            @include type--paragraph;
            margin-bottom: 16px;
            justify-content: center;
            text-align: center;
        }

        .list-numbers--text-col {
            @include grid--span((ratio: 1 / 2, mq: '>lg'));
        }

        .list-numbers--entry {
            @include grid--span((ratio: 1 / 4, mq: '>md'));
            @include grid--span((ratio: 2 / 5, mq: 'md'));
            @include grid--span((ratio: 3 / 4, mq: '<=sm'));
            overflow: hidden;
        }

        .list-numbers--row.list-numbers--row.list-numbers--row {
            flex-flow: row nowrap;

            @include mq('>md') {
                justify-content: center;
            }
        }

        .list-numbers--actions {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            margin-top: 50px;
        }
    }

    // Facets
    & {
        @include section-background($selector: 'list-numbers', $add-section-padding: true);

        @each $color-key, $color-value in $accents {
            &.list-numbers__foreground-accent-#{$color-key} {
                background-color: $color-value;
            }
        }
    }

    // States
}
