.comp-list-media {
    // Vars

    // Support

    // Module
    & {
        .comp-list-media--title {
            margin-bottom: 32px;
            color: $c--brand-blue-dark;
        }

        .comp-list-media--text {
            @include type--paragraph;
            justify-content: center;
        }

        .comp-list-media--row {
            justify-content: center;
        }

        .comp-list-media--col {
            @include grid--span((ratio: 12 / 12, mq: '<=md'));
            @include grid--span((ratio: 8 / 12, mq: 'lg'));
            @include grid--span((ratio: 6 / 12, mq: '>lg'));
        }

        .comp-list-media--list {
            margin-top: 48px;
            padding: 0 16px;
        }
    }

    // Facets

    // States
}
