.form-general-messages {
    // Vars

    // Support

    // Module
    & {
        .form-general-messages--item {
            display: flex;
            width: 100%;
            line-height: initial;

            ~ .form-general-messages--item {
                margin-top: 4px;
            }
        }

        .form-general-messages--text {
            display: inline-block;
            font-size: 1.2rem;
            line-height: 1.3;
            color: $c--signal-error;
        }
    }

    // Facets

    // States
}
