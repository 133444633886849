.header-basic {
    // Vars

    // Support

    // Module
    & {
        .header-basic--content-col {
            @include grid--span((ratio: 8 / 12));
            @include grid--span((ratio: 12 / 12, mq: '<=md'));

            :last-child {
                margin-bottom: 0;
            }
        }

        .header-basic--title {
            @include type--h1;
            margin-bottom: 24px;

            @include mq('<=lg') {
                margin-bottom: 16px;
            }

            &.header-basic--title__small {
                @include type--h2;
            }
        }

        .header-basic--lead {
            @include type--lead;
            margin-bottom: 39px;
        }

        .header-basic--text {
            margin-bottom: 39px;
        }

        .header-basic--link-list {
            display: flex;
            flex-flow: row;
            margin-bottom: 40px;

            @include mq('<=sm') {
                display: block;
            }
        }

        .header-basic--link-list-item {
            margin: 0 40px;

            @include mq('<=sm') {
                margin: 0 0 16px;
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .header-basic--link-title {
            @include type--label-small;
            display: block;
            color: $c--brand-blue-dark;
            margin-bottom: 2px;
        }
    }

    // Facets

    // States
}
