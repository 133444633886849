.list-job-openings {
    // Vars

    // Support

    // Module
    & {
        .list-job-openings--item ~ .list-job-openings--item {
            margin-top: 28px;
        }

        .list-job-openings--item {
            @include grid--span((ratio: 1 / 1));
            @include grid--span((ratio: 1 / 2, mq: 'md'));
            @include grid--span((ratio: 1 / 3, mq: '>md'));

            @include mq('md') {
                &:nth-child(-n+2) {
                    margin-top: 0;
                }
            }

            @include mq('>md') {
                &:nth-child(-n+3) {
                    margin-top: 0;
                }
            }
        }
    }
    // Facets
    // States
}
