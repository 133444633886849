/// Base mixin for buttons
///
@mixin btn--all {
    // Vars

    // Support

    // Module
    & {
        @include btn--base;
    }

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--all-base;
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--all-focus;
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': default, 'active': true)) {
            @include btn--all-active;
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default)) {
            @include btn--all-disabled;
        }
    }
}

/// Base
///
@mixin btn--all-base {
    text-decoration: none;
}

/// Focus
///
@mixin btn--all-focus {
    text-decoration: none;
    cursor: pointer;
}

/// Active
///
@mixin btn--all-active {
    text-decoration: none;
    cursor: pointer;
}

/// Disabled
///
@mixin btn--all-disabled {
    text-decoration: none;
    cursor: default;
}
