/// Circle shaped button
///
@mixin btn--bubble {
    // Vars

    // Support

    // Module
    & {
        min-width: 0;
        border-radius: 50%;

        .btn--icon {
            margin: 0;
        }
    }

    // Facets
    & {
        &.btn__medium {
            @include btn--size-bubble-medium;
        }
    }

    // States
}
