.spider-svg {
    // Vars

    // Support

    // Module
    & {
        & {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .spider-svg--drawing-area {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 100%;
            width: 100%;

            transform: translate(-50%, -50%);
        }

        .spider-svg--triangle {
            fill: rgba(0, 147, 217, 0.4);
            stroke: $c--accent-blue;
            stroke-width: 2px;
            stroke-linejoin: round;
        }
    }

    // Facets

    // States
}
