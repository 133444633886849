$trs--base-speed: 200ms;
$trs--base-fn: ease;
$trs--base: $trs--base-speed $trs--base-fn;

$trs--scroll-reveal-speed: 400ms;
$trs--scroll-reveal-fn: ease;
$trs--scroll-reveal: $trs--scroll-reveal-speed $trs--scroll-reveal-fn;

$trs--common-property-list: color, border-color, background-color, opacity, transform;
$trs--common-props: transition-props($trs--base, $trs--common-property-list...);
