/// Secondary
///
@mixin btn--search {
    // Vars

    // Support

    // Module

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--search-base;
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--search-focus;
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': default, 'active': true)) {
            @include btn--search-active;
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default)) {
            @include btn--search-disabled;
        }
    }
}

/// Base
///
@mixin btn--search-base {
    color: $c--brand-blue-dark;
    background-color: $c--monochrome-silver;
}

/// Focus
///
@mixin btn--search-focus {
    color: $c--accent-blue;
}

/// Active
///
@mixin btn--search-active {
    color: $c--accent-blue;
}

/// Disabled
///
@mixin btn--search-disabled {
    color: $c--monochrome-light-gray;
}
