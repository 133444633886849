.comp-basic {
    // Vars

    // Support

    // Module
    & {
        .comp-basic--row {
            justify-content: center;
        }

        .comp-basic--col {
            @include grid--span((ratio: 1 / 1));
            @include grid--span((ratio: 8 / 12, mq: 'md'));
            @include grid--span((ratio: 6 / 12, mq: '>md'));
            @include grid--span((ratio: 12 / 12, mq: 'print'));
        }

        .comp-basic--item ~ .comp-basic--item {
            margin-top: 36px;
        }

        .comp-basic--sub-heading {
            display: block;
            margin-bottom: 6px;
        }

        .comp-basic--title {
            margin-top: 0;
        }
    }

    // Facets
    & {
        &.comp-basic__two-col {
            .comp-basic--col {
                @include grid--span((ratio: 1 / 1));
                @include grid--span((ratio: 8 / 12, mq: 'xxl'));
            }

            .comp-basic--item {
                @include grid--span((ratio: 1 / 2, mq: '>sm'));
            }

            .comp-basic--item {
                &:nth-child(-n+2) {
                    @include mq('>sm') {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    // States
}
