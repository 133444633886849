.steps-entry {
    // Vars

    // Support

    // Module
    & {
        & {
            z-index: 10;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            background-color: $c--monochrome-white;
            border-radius: 9px;
            padding: 32px;

            @include mq('>sm') {
                min-width: 260px;
            }

            @include mq('>md') {
                min-width: 324px;
            }

            @include mq('>lg') {
                min-width: 516px;
            }
        }

        .steps-entry--title {
            @include type--h1;
            margin-bottom: 16px;
        }

        .steps-entry--list-elements.steps-entry--list-elements.steps-entry--list-elements.steps-entry--list-elements {
            .u-rich-text {
                ul {
                    li {
                        width: 90%;
                    }
                }
            }
        }
    }

    // Facets

    // States
}
