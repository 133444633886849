.fluid-scrollable {
    // Vars
    // Support
    // Module
    & {
        .fluid-scrollable--container {
            @include mq('<=md') {
                min-width: 100vw;
                max-width: none;
            }
        }

        .fluid-scrollable--row {
            @include mq('<=md') {
                overflow-x: auto;
            }
        }
    }

    // Facets
    // States
}
