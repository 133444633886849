.facts-image-chart {
    // Vars
    // Support
    // Module
    & {
        display: flex;
        align-items: center;

        .facts-image-chart--chart {
            flex: 0 0 172px;
            height: 172px;
            width: 172px;
            margin-right: 24px;

            @include mq('<=sm') {
                margin-right: 16px;
            }
        }

        .facts-image-chart--text {
            @include type--label;
        }
    }

    // Facets
    & {
        @each $color-key, $color-value in $accents {
            &.facts-image-chart__accent-#{$color-key} {
                .facts-image-chart--chart {
                    color: $color-value;
                }
            }
        }

        &.facts-image-chart__monochrome-silver {
            .facts-image-chart--chart {
                color: $c--monochrome-silver;
            }
        }
    }
    // States
}
