.list-link-entry {
    // Vars
    $sz--inner-offset: 16px;

    // Support

    // Module
    & {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        transition: color $trs--base;

        .list-link-entry--content {
            padding-right: $sz--inner-offset;
            flex: 0 1 100%;
        }

        .list-link-entry--title {
            @include type--label;
        }

        .list-link-entry--icon-start {
            padding-right: 16px;
        }

        .list-link-entry--icon-end {
            position: relative;
            transition: transform $trs--base;
        }

        @include has-focus(true) {
            color: $c--accent-blue;

            .list-link-entry--icon-end {
                transform: translateX($sz--inner-offset);
            }
        }
    }

    // Facets
    & {
        &.list-link-entry__base,
        &.list-link-entry__leftright {
            padding: 0 $sz--inner-offset;
        }

        &.list-link-entry__bordered {
            padding: 14px 20px;
            border-top: 1px solid rgba($c--brand-blue-dark, 0.2);
            border-bottom: 1px solid rgba($c--brand-blue-dark, 0.2);
        }

        &.list-link-entry__media {
            padding: 14px 20px 14px 16px;

            @include has-focus(true) {
                color: $c--accent-blue;

                .list-link-entry--icon-end {
                    transform: none;
                }
            }
        }
    }

    // States
}
