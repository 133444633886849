.list-tabs {
    // Vars
    $tabs-height: 52px;

    // Support

    // Module
    & {
        @include grid--root((row: 'row', cols: ('col')));
        @include grid--space((gutter: (16px 16px), row: 'row', cols: ('col'), mq: '<=lg'));
        @include grid--space((gutter: (16px 24px), row: 'row', cols: ('col'), mq: '>lg'));

        .list-tabs--row {
            @include mq('<=sm') {
                flex-wrap: nowrap;
                overflow-x: auto;
                padding-bottom: 8px;
                display: none;
            }
        }

        .list-tabs--mobile.list-tabs--mobile {
            @include mq('>sm') {
                display: none;
            }
        }

        .list-tabs--col {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .list-tabs--link {
            @include type--tabs;

            &.list-tabs--link__active {
                position: relative;
                color: $c--accent-blue;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -2px;
                    display: block;
                    width: 100%;
                    height: 2px;
                    background: $c--accent-blue;
                }
            }
        }
    }

    // Facets
    // States
}
