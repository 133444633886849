/// Medium
///
@mixin btn--size-medium {
    @include type--button;
    padding: 19px 60px;

    @include mq('<=sm') {
        padding: 15px 40px 14px;
    }
}

/// Small
///
@mixin btn--size-small {
    @include type--button;
    padding: 16px 28px;
}

/// Medium bubble
///
@mixin btn--size-bubble-medium {
    padding: 4px;
}
