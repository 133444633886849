.newsletter-subscription {
    // Vars

    // Support

    // Module
    & {
        & {
            @include section-padding;
        }

        .newsletter-subscription--row {
            justify-content: center;
        }

        .newsletter-subscription--col {
            text-align: center;

            @include grid--span((ratio: 6 / 12, mq: '>md'));
            @include grid--span((ratio: 12 / 12, mq: '<=md'));
        }

        .newsletter-subscription--image {
            margin-bottom: 16px;
        }

        .newsletter-subscription--title,
        .newsletter-subscription--description {
            display: inline-block;
            width: 100%;
        }

        .newsletter-subscription--title {
            margin-bottom: 16px;

            @include type--h3;
        }

        .newsletter-subscription--description {
            margin-bottom: 24px;
        }
    }

    // Facets
    & {
        @each $color-key, $color-value in $backgrounds {
            &.newsletter-subscription__accent-#{$color-key} {
                background-color: $color-value;
            }
        }
    }

    // States
}
