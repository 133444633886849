.tag-list {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'row', cols: ('col')));
        @include grid--space((gutter: (8px 8px), row: 'row', cols: ('col')));

        .tag-list--tag {
            @include type--paragraph-x-small;
            display: block;
            padding: 3px 12px;
            background-color: $c--accent-cyan;
            transition: transition-props($trs--base, color, background-color);

            @include has-focus(true) {
                color: $c--monochrome-white;
                background-color: $c--brand-blue;
            }
        }
    }

    // Facets
    & {
        &.tag-list__image {
            .tag-list--tag {
                background-color: $c--accent-yellow;

                @include has-focus(true) {
                    color: $c--brand-blue-dark;
                }
            }
        }
    }

    // States
}
