.slider-feed-entry {
    // Vars
    $container-sizing: (
        (mq: ('xxl', 'lg'), padding: 40px),
        (mq: 'xl', padding: 60px),
    );

    // Support

    // Module
    & {
        & {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;

            @each $value in $container-sizing {
                @include mq(map-get($value, 'mq')) {
                    padding-bottom: map-get($value, 'padding');
                }
            }
        }

        .slider-feed-entry--image-wrapper {
            overflow: hidden;
        }

        .slider-feed-entry--image {
            display: block;
            width: 100%;
            height: auto;
            transition: transform $trs--base;

            @include mq('>md') {
                position: relative;
            }
        }

        .slider-feed-entry--image-tags {
            position: absolute;
            top: 0;
            right: 16px;

            @include mq('<=md') {
                right: 12px;
                top: 0;
            }
        }

        .slider-feed-entry--tags {
            @include mq('<=md') {
                padding-bottom: 40px;
            }
        }

        .slider-feed-entry--content {
            display: flex;
            height: 172px;

            @include mq('>md') {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
            }

            @include mq('lg') {
                height: 135px;
            }

            @include mq('md') {
                height: 175px;
            }

            @include mq('<=sm') {
                height: 205px;
            }
        }

        .slider-feed-entry--content-col {
            position: relative;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: space-between;
            padding: 16px 16px 0;
            background: $c--monochrome-silver;

            @include grid--span((ratio: 7 / 12, mq: '>md'));
            @include grid--push((ratio: 1.1 / 12 * -1, mq: '>md'));

            @include mq('<=lg') {
                padding: 12px 12px 0;
            }

            @include mq('<=sm') {
                padding: 12px 0 0;
            }

            @include mq('<=md') {
                position: static;
            }
        }

        .slider-feed-entry--title {
            @include type--h2;
            margin-bottom: 16px;
            transition: color $trs--base;

            @include mq('<=lg') {
                margin-bottom: 12px;
            }
        }

        .slider-feed-entry--label {
            @include type--paragraph-small;
            display: block;
            min-height: 21px;
            margin-bottom: 8px;

            @include mq('<=lg') {
                margin-bottom: 4px;
            }
        }
    }

    // Facets
    & {
        @each $color-key, $color-value in $label-feed {
            &.slider-feed-entry__accent-#{$color-key} {
                .slider-feed-entry--label {
                    color: $color-value;
                }
            }
        }

        .slider-feed-entry--image__big {
            @include mq('<=sm') {
                display: none;
            }
        }

        .slider-feed-entry--image__small {
            @include mq('>sm') {
                display: none;
            }
        }
    }

    // States
    & {
        & {
            @include has-focus(true) {
                .slider-feed-entry--title {
                    color: $c--label-link;
                }

                .slider-feed-entry--image {
                    transform: scale(1.1);
                }

                .slider-feed-entry--icon {
                    transform: translateX(16px);
                }
            }
        }
    }
}
