/// Monochrome special decoration colors
///
$c--monochrome-white: #ffffff;
$c--monochrome-black: #000000;
$c--monochrome-silver: #f4f6fb;
$c--monochrome-silver2: #ffffff;
$c--monochrome-medium-gray: #e1e5f0;
$c--monochrome-transparent-gray: rgba(0, 0, 0, 0.10);

// Unused reserve colors
$c--monochrome-black-gray: #222222;
$c--monochrome-dark-gray: #666666;
$c--monochrome-light-gray: #eeeeee;
$c--monochrome-paper-white: #fbfbfb;
$c--monochrome-steel: #9fa8be;

$monochromes: (
    'white': $c--monochrome-white,
    'black': $c--monochrome-black,
    'silver': $c--monochrome-silver,
    'medium-gray': $c--monochrome-medium-gray,
    'steel': $c--monochrome-steel,
    // Unused reserve colors
    'black-gray': $c--monochrome-black-gray,
    'dark-gray': $c--monochrome-dark-gray,
    'light-gray': $c--monochrome-light-gray,
    'paper-white': $c--monochrome-paper-white,
);

/// Brand specific colors
///
$c--brand-blue: #0065a1;
$c--brand-blue-dark: #064266;
$c--brand-blue-dark-divider: rgba(6, 66, 102, 0.1);
$c--brand-red: #e9004b;
$c--brand-linkedin: #3b5997;
$c--brand-facebook: #3b5997;
$c--brand-twitter: #1da1f2;
$c--brand-link: #9fa8be;
$c--brand-mail: #9fa8be;

$brands: (
    'blue': $c--brand-blue,
    'blue-dark': $c--brand-blue-dark,
    'red': $c--brand-red,
    'linkedin': $c--brand-linkedin,
    'facebook': $c--brand-facebook,
    'twitter': $c--brand-twitter,
    'link': $c--brand-link,
    'mail': $c--brand-mail,
);

/// Accent colors
///
$c--accent-blue: #0093d9;
$c--accent-pink: #ff5caa;
$c--accent-yellow: #fee45d;
$c--accent-cyan: #8ce5e5;
$c--accent-lightblue: #4cb3e4;

$accents: (
    'blue': $c--accent-blue,
    'pink': $c--accent-pink,
    'yellow': $c--accent-yellow,
    'cyan': $c--accent-cyan,
    'lightblue': $c--accent-lightblue,
);

/// Background colors
///
$c--background-blue: #4cb3e4;
$c--background-pink: #ff7dbb;
$c--background-yellow: #fee45d;
$c--background-cyan: #8ce5e5;

$backgrounds: (
    'blue': $c--background-blue,
    'pink': $c--background-pink,
    'yellow': $c--background-yellow,
    'cyan': $c--background-cyan,
);

/// Label feed colors for
///
$c--label-feed-blue: #4cb3e4;
$c--label-feed-pink: #ff7dbb;

$label-feed: (
    'blue': $c--label-feed-blue,
    'pink': $c--label-feed-pink,
);

/// Single purpose colors
///
$c--label-text: $c--brand-blue-dark;
$c--label-link: $c--accent-blue;
$c--label-selection: null;

$labels: (
    'text': $c--label-text,
    'link': $c--label-link,
    'selection': $c--label-selection,
);

/// Signal colors
///
$c--signal-info: #0000ff;
$c--signal-error: #e9004b;
$c--signal-warning: #ffff00;
$c--signal-success: #008000;

$signals: (
    'info': $c--signal-info,
    'error': $c--signal-error,
    'warning': $c--signal-warning,
    'success': $c--signal-success,
);

/// Main color map
///
$color-maps: (
    monochrome: $monochromes,
    brand: $brands,
    accent: $accents,
    label: $labels,
    signal: $signals,
);
