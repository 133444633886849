.enumeration.enumeration {
    // Vars

    // Support

    // Module
    & {
        & {
            display: flex;
        }

        .enumeration--title {
            @include type--h2;
            margin-bottom: 16px;

            @include mq('<=md') {
                margin-bottom: 12px;
            }
        }

        .enumeration--description {
            @include type--paragraph;
            margin-bottom: 40px;

            @include mq('<=md') {
                margin-bottom: 32px;
            }
        }

        .enumeration--col {
            @include grid--span((ratio: 6 / 12, mq: '>lg'));
            @include grid--span((ratio: 8 / 12, mq: 'lg'));
            @include grid--span((ratio: 10 / 12, mq: 'md'));
            @include grid--span((ratio: 4 / 4, mq: '<=sm'));
        }

        .enumeration--row {
            justify-content: center;
        }
    }

    // Facets
    & {
        @include section-background($selector: 'enumeration', $add-section-padding: true);
    }

    // States
}
