/// Form field facet
///
@mixin ui-form-field--primary {
    // Vars
    $sz--input-padding-top: 17px;
    $sz--input-padding-x: 16px;
    $sz--input-padding-bottom: 15px;

    $sz--input-border-width: 1px;

    $fz--bool-label: 1.4rem / 1rem * 10px; // Convert to px
    $lh--bool-label: 1.5;
    $sz--bool-box-gutter: 5px;
    $sz--bool-box: ($fz--bool-label + (2 * $sz--bool-box-gutter));
    $sz--bool-box-border: 2px;

    $sz--actions-icon-offset: 0;
    $sz--actions-icon-margin: 8px;
    $sz--actions-icon-width: 28px;

    $sz--floating-label-offset: 7px;
    $sz--floating-label-padding-top: $sz--input-padding-top + $sz--floating-label-offset;
    $sz--floating-label-padding-bottom: $sz--input-padding-bottom - $sz--floating-label-offset;

    // Support

    // Fields
    & {
        @include ui-form-field--primary-all;

        &.ui-form-field__hidden {
            @include ui-form-field--primary-hidden;
        }

        &.ui-form-field__input {
            @include ui-form-field--primary-input(
                $sz--padding-top: $sz--input-padding-top,
                $sz--padding-x: $sz--input-padding-x,
                $sz--padding-bottom: $sz--input-padding-bottom,
                $sz--border-width: $sz--input-border-width
            );

            &.ui-form-field__textarea {
                @include ui-form-field--primary-textarea;
            }

            &.ui-form-field__select {
                @include ui-form-field--primary-select;
                @include ui-form-field--primary-has-actions(
                    $sz--icon-margin: $sz--actions-icon-margin,
                    $sz--icon-offset: $sz--actions-icon-offset,
                    $sz--icon-width: $sz--actions-icon-width,
                    $sz--padding-top: $sz--input-padding-top,
                    $sz--padding-x: $sz--input-padding-x,
                    $sz--padding-bottom: $sz--input-padding-bottom
                );
            }
        }

        &.ui-form-field__bool {
            @include ui-form-field--primary-bool(
                $fz--label: $fz--bool-label,
                $lh--label: $lh--bool-label,
                $sz--box: $sz--bool-box,
                $sz--box-border: $sz--bool-box-border
            );

            &.ui-form-field__radio {
                @include ui-form-field--primary-radio(
                    $sz--box: $sz--bool-box
                );
            }

            &.ui-form-field__checkbox {
                @include ui-form-field--primary-checkbox;
            }

            &.ui-form-field__file {
                @include ui-form-field--primary-file;
            }
        }
    }

    // Addons
    & {
        &.ui-form-field__floating-label {
            @include ui-form-field--primary--floating-label(
                $sz--padding-top: $sz--floating-label-padding-top,
                $sz--padding-x: $sz--input-padding-x,
                $sz--padding-bottom: $sz--floating-label-padding-bottom,
                $sz--border-width: $sz--input-border-width
            );
        }

        &.ui-form-field__has-actions {
            @include ui-form-field--primary-has-actions(
                $sz--icon-margin: $sz--actions-icon-margin,
                $sz--icon-offset: $sz--actions-icon-offset,
                $sz--icon-width: $sz--actions-icon-width,
                $sz--padding-top: $sz--input-padding-top,
                $sz--padding-x: $sz--input-padding-x,
                $sz--padding-bottom: $sz--input-padding-bottom
            );
        }

        &.ui-form-field__list {
            @include ui-form-field--primary-list;
        }
    }
}
