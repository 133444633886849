@mixin ui-form-field--primary-list() {
    // Vars
    $sz--list-gutter-x: 20px;
    $sz--list-gutter-y: 16px;

    // Module
    & {
        @include grid--root((cols: ('input-container')));
        @include grid--space((gutter: ($sz--list-gutter-y, $sz--list-gutter-x), cols: ('input-container', 'messages-container')));

        .ui-form-field--list-item {
            width: 100%;
        }

        .ui-form-field--title-label {
            @include mq('>md') {
                padding-top: 2px;
            }
        }

        .ui-form-field--input-container {
            flex: 0 1 auto;
        }

        .ui-form-field--messages-container {
            flex: 0 1 100%;
        }
    }

    // Facets
    & {
        &.ui-form-field__vertical {
            .ui-form-field--row {
                flex-flow: column nowrap;
            }
        }
    }
}
