.chart {
    // Vars
    // Support
    // Module
    & {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .chart--canvas {
            position: absolute;
            top: 0;
            left: 0;
        }

        .chart--value {
            @include type--chart-number;
            display: flex;
        }
    }

    // Facets
    & {
        @each $color-key, $color-value in $accents {
            &.chart__#{$color-key} {
                color: $color-value;
            }
        }
    }
    // States
}
