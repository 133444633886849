.breadcrumb-list {
    // Vars
    $default-left-offset: 180px;
    $medium-left-offset: 180px;

    // Support

    // Module
    & {
        & {
            z-index: z-index('breadcrumbs');
            position: absolute;
            top: -24px;
            left: 172px;

            @include mq('<=sm') {
                display: none;
            }
        }

        .breadcrumb-list--container {
            overflow-x: auto;
            overflow-y: hidden;
        }

        .breadcrumb-list--list {
            position: relative;
            display: flex;
            justify-content: flex-start;
            overflow: visible;
            white-space: nowrap;
            border: 1px solid $c--monochrome-medium-gray;
            box-sizing: border-box;
            border-radius: 50px;
            padding: 15px 24px;
            background: $c--monochrome-white;
        }

        .breadcrumb-list--list-container {
            border: 1px solid $c--monochrome-medium-gray;
            box-sizing: border-box;
            border-radius: 50px;
            padding: 15px 24px;
        }

        .breadcrumb-list--row {
            justify-content: center;
        }

        .breadcrumb-list--col {
            @include grid--span((ratio: 12 / 12));
        }
    }

    // Facets

    // States
}
