.navigation-sub-desktop {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'nav-items', cols: ('nav-item')));
        @include grid--space((gutter: 0, row: 'nav-items', cols: ('nav-item')));
        position: relative;
        background: $c--monochrome-white;

        @include mq('<=md') {
            display: none;
        }

        .navigation-sub-desktop--row.navigation-sub-desktop--row {
            margin: 0;
        }

        .navigation-sub-desktop--nav {
            width: 100%;
            background-color: $c--monochrome-silver2;
        }

        .navigation-sub-desktop--nav-items.navigation-sub-desktop--nav-items {
            flex-flow: row nowrap;
        }

        .navigation-sub-desktop--nav-item {
            @include grid--span((ratio: 1 / 4));
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            align-items: stretch;
            overflow: visible;
            padding-bottom: 16px;

            &:last-child {
                .navigation-sub-desktop--nav-item-inner {
                    border-right: none;
                }
            }
        }

        .navigation-sub-desktop--nav-item-inner {
            display: flex;
            width: 100%;
            border-right: 1px solid $c--monochrome-transparent-gray;
        }
    }

    // Facets

    // States
}
