.adaptive-nav {
    // Vars

    // Support
    @mixin adaptive-nav--nav-link() {
        @include type--tabs;
        display: block;

        @include has-focus(true) {
            color: $c--accent-blue;
        }
    }

    // Module
    & {
        @include grid--root((row: 'items', cols: ('item')));
        @include grid--space((mq: 'md', gutter: 24px, row: 'items', cols: ('item')));
        @include grid--space((mq: '<=sm', gutter: 16px, row: 'items', cols: ('item')));

        .adaptive-nav--more {
            z-index: 1;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;

            @include mq('md') {
                padding-left: 12px;
            }

            @include mq('<=sm') {
                padding-left: 8px;
            }

            &.adaptive-nav--more__no-entries {
                visibility: hidden;
                pointer-events: none;
            }

            &.adaptive-nav--more__is-open,
            &.adaptive-nav--more__has-selected-more-entries {
                .adaptive-nav--more-btn {
                    @include has-focus(default) {
                        color: $c--accent-blue;
                    }
                }
            }
        }

        .adaptive-nav--more-btn {
            @include adaptive-nav--nav-link;
            padding-left: 16px;
            padding-right: 16px;
            min-width: 106px;
            height: 100%;
            text-align: center;
            background-color: $c--monochrome-medium-gray;
            cursor: pointer;
        }

        .adaptive-nav--items {
            flex-flow: row nowrap;
        }

        .adaptive-nav--item {
            display: flex;
            flex-flow: row nowrap;
            align-items: stretch;
            height: $sz--navigation-sub-height-mobile;
            white-space: nowrap;

            &.adaptive-nav--item__more {
                padding: 0 24px;
            }
        }

        .adaptive-nav--link {
            @include adaptive-nav--nav-link;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        .adaptive-nav--more-menu {
            z-index: 1;
            position: absolute;
            top: 100%;
            right: 0;
        }

        .adaptive-nav--more-items {
            width: 200px;
            padding: 8px 0;
            background-color: $c--monochrome-medium-gray;
        }

        .adaptive-nav--more-link {
            @include adaptive-nav--nav-link;
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    // Facets
    & {
        // Nav item
        & {
            .adaptive-nav--item {
                &.adaptive-nav--item__is-hidden {
                    visibility: hidden;
                    pointer-events: none;
                }

                &.adaptive-nav--item__is-selected,
                &.adaptive-nav--item__is-open {
                    .adaptive-nav--link {
                        // stylelint-disable-next-line max-nesting-depth
                        @include has-focus(default) {
                            color: $c--accent-blue;
                        }
                    }
                }
            }
        }
    }

    // States
}
